import { memo, useContext, useMemo } from 'react';
import { DashboardContext } from './DashboardContext';
import DailyDashboard from './DailyDashboard';
import CustomBackground from './components/CustomBackground';

const DashboardContainer = memo(() => {
    const { activeDashboard } = useContext(DashboardContext);

    const ActivedDashboard = useMemo(() => {
        if (activeDashboard?.type?.value === 'default') return <DailyDashboard />;
        return null;
    }, [activeDashboard?.type?.value]);

    return (
        <div className="fm-dashboard-container">
            {ActivedDashboard}
            {!!activeDashboard && (
                <CustomBackground
                    color={activeDashboard?.config?.background?.color}
                    brightness={activeDashboard?.config?.background?.brightness}
                />
            )}
        </div>
    );
});

export default DashboardContainer;
