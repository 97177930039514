import React, { memo, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Filters, FiltersContext, QuickFilters } from '@web/web5';
import { EntityFiltersActions, ConfigActions, FuzzyActions, ServerListActions } from 'actions';
import { mappedActivityTypeIds } from 'models/ActivityModel';
import { SALESORDERS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { FuzzyList } from 'utils/fuzzy';
import { logEvent } from 'utils/tracking';
import { getBackendBoolean } from 'utils/fm';
import { isEqual } from 'utils/objects';
import {
    getCrossID,
    getMappedFilters,
    getRealID,
    translateFollowingItemForWeb,
    translateExtraBoolean,
    CROSS_FILTER_ID_SEPARATOR,
} from 'utils/filters';
import { intercomHideAndShow } from 'utils/intercom';
import { ACTIVITIES, OPPORTUNITIES, CONVERSATIONS } from 'constants/Entities';
import { userflowHideAndShow } from 'utils/userflow';
import { inConversations } from 'containers/Activities/utils/conversations';

import useAdvancedFilters from './hooks/useAdvancedFilters';
import useCrossFilters from './hooks/useCrossFilters';
import { SENT_TO_BE_AS_STANDARD_FILTERS } from './constants';

const mapStateToProps = (state, ownProps) => {
    const name = ownProps.entity ? ownProps.entity.entity : '';
    const entityFilters = state.entityFilters[ownProps.entity.entity];
    const filters = entityFilters ? entityFilters.filters : null;
    const operators = entityFilters ? entityFilters?.operators : {};
    const crossFilters = entityFilters ? entityFilters.crossFilters : {};
    const crossOperators = entityFilters ? entityFilters.crossOperators : {};
    const serverList = state.serverList;

    let principalFilter = null;

    if (
        state.config.userData &&
        state.config.userData.principalFilter &&
        state.config.userData.principalFilter[ownProps.entity.entity]
    ) {
        principalFilter = state.config.userData.principalFilter[ownProps.entity.entity];
    }

    const license = state.config?.subscriptionModeCode || '';

    return {
        filters,
        serverList,
        principalFilter,
        cache: state.config.components[`${name}_filters`],
        views: state.config.views,
        viewsPublicManagement: state.config.permission.viewsPublicManagement,
        viewsPrivateManagement: state.config.permission.viewsPrivateManagement,
        standardFieldsSchemaMap:
            state.config?.standardFieldsSchemaMap?.[ownProps?.entity?.extraFieldName] || null,
        filterDragging: entityFilters?.filterDragging,
        operators,
        license,
        enableSalesOrdersTypes: state.config?.userData?.enableSalesOrdersTypes,
        crossFilters,
        crossOperators,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    let name = ownProps.entity ? ownProps.entity.entity : '';
    const setConfigWeb = bindActionCreators(ConfigActions, dispatch).setConfigWeb;

    return {
        initFilters: bindActionCreators(EntityFiltersActions, dispatch).initFilters,
        toggleFilters: bindActionCreators(EntityFiltersActions, dispatch).toggleFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        changeAdvancedFilter: bindActionCreators(EntityFiltersActions, dispatch)
            .changeAdvancedFilter,
        deleteMultipleFilters: bindActionCreators(EntityFiltersActions, dispatch)
            .deleteMultipleFilters,
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        saveCache: (config, partial) => setConfigWeb(`${name}_filters`, config, partial),
        setConfigWeb,
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        removeView: bindActionCreators(EntityFiltersActions, dispatch).removeView,
        createView: bindActionCreators(EntityFiltersActions, dispatch).createView,
        setFilterDragging: bindActionCreators(EntityFiltersActions, dispatch).setFilterDragging,
        disableForceCrossFilters: bindActionCreators(EntityFiltersActions, dispatch)
            .disableForceCrossFilters,
    };
};

const NewEntityFilters = memo(
    ({
        className,
        useQuickFilters,
        useQuickView,
        useViewsInFilters,
        useViewsInMenu,
        entity,
        isOpen,
        defaultQuickFilters = [],
        defaultFilters = [],
        hiddenFilters = [],
        cache,
        filters,
        serverList,
        principalFilter,
        initFilters,
        changeFilter,
        deleteMultipleFilters,
        clearFilters,
        toggleFilters,
        filtersAddButtonSchema = [],
        saveCache,
        getFuzzy,
        getList,
        views,
        createView,
        removeView,
        useDrawer,
        dontIgnoreTheseFilters,
        viewsPublicManagement,
        viewsPrivateManagement,
        standardFieldsSchemaMap,
        getCanClearAll,
        clearSpecificFilter,
        setConfigWeb,
        filterDragging,
        setFilterDragging,
        operators,
        changeAdvancedFilter,
        license,
        enableSalesOrdersTypes,
        crossFilters,
        crossOperators,
        disableForceCrossFilters,
        ...props
    }) => {
        /**
         * Available dispatch actions:
         *
         * - `init`: Initializes the state with provided payload data.
         * - `openFilters`: Updates `openFilters` state.
         * - `openQuickFilters`: Updates `openQuickFilters` state.
         * - `visibleFilters`: Sets visible filters.
         * - `quickFilters`: Updates the list of quick filters.
         * - `crossFilters`: Updates cross-schema mappings.
         * - `saveConfig`: Saves the entire configuration using payload data.
         *
         */
        const { state, dispatch } = useContext(FiltersContext);
        const isFirstLoad = useRef(true);
        const debounce = useRef(null);
        const cachedOptions = useRef({});
        const quickFilterRef = useRef(cache?.quickFilters || []);
        const openFiltersRef = useRef(cache?.openFiltersRef || {});
        const openQuickFiltersRef = useRef(cache?.openQuickFilters || {});
        const location = useLocation();

        const {
            crossLabels,
            getCrossFilters,
            getCrossValues,
            getDefaultFilters,
            getFinalCrossSchema,
            getHasCrossFiltersActive,
            getModalOptionsSchema,
            hasCrossFilters,
            initCrossFilters,
        } = useCrossFilters({
            entity,
        });

        const { getAllowedOperators, getHasAdvancedFilters, onChangeOperator } = useAdvancedFilters(
            {
                entity,
            },
        );

        useEffect(() => {
            // Sync local state with cached state
            if (useQuickFilters) {
                if (!isEqual(cache?.quickFilters, quickFilterRef.current)) {
                    dispatch({ type: 'quickFilters', payload: cache?.quickFilters || [] });
                }
                if (!isEqual(cache?.openQuickFilters, openQuickFiltersRef.current)) {
                    dispatch({ type: 'openQuickFilters', payload: cache?.openQuickFilters || {} });
                }
                openQuickFiltersRef.current = cache?.openQuickFilters;
            } else {
                if (!isEqual(cache?.openFilters, openFiltersRef.current)) {
                    dispatch({ type: 'openFilters', payload: cache?.openFilters || {} });
                }
                openFiltersRef.current = cache?.openFilters;
            }
            quickFilterRef.current = cache?.quickFilters;
        }, [
            cache?.openFilters,
            cache?.openQuickFilters,
            cache?.quickFilters,
            dispatch,
            useQuickFilters,
        ]);

        const addFixedFilters = useCallback(
            (quickFilters) => {
                // --------------------------------------------------------------------------------------
                // GOKU-5779:
                // Temporary fix to force the "Pending Conversations" filter into activities quickfilters
                // --------------------------------------------------------------------------------------
                if (
                    !quickFilters.includes('pendingConversations') &&
                    entity.trueName === ACTIVITIES.trueName
                )
                    quickFilters.push('pendingConversations');

                if (
                    !quickFilters.includes('isMarketingEmail') &&
                    entity.trueName === ACTIVITIES.trueName
                )
                    quickFilters.push('isMarketingEmail');

                if (!quickFilters.includes('state') && entity.trueName === OPPORTUNITIES.trueName) {
                    quickFilters.unshift('state');
                }
            },
            [entity],
        );

        const getFixedVisibleFilters = useCallback(() => {
            let visible = [];
            if (entity.trueName === ACTIVITIES.trueName) {
                visible.push('isMarketingEmail');
            }
            return visible;
        }, [entity?.trueName]);

        const getStateCached = useCallback(() => {
            let cachedState = null;
            let visibleFilters = [];
            let quickFilters = [];
            let openFilters = {};
            let openQuickFilters = {};
            let crossSchema = {};

            if (cache) {
                quickFilters = cache.hasOwnProperty('quickFilters')
                    ? cache.quickFilters || []
                    : defaultQuickFilters;

                addFixedFilters(quickFilters);

                // All quick filters have to be visible for consistency
                visibleFilters = new Set([
                    ...(cache.visibleFilters || []),
                    ...quickFilters,
                    ...getFixedVisibleFilters(),
                ]);

                visibleFilters = Array.from(visibleFilters);
                visibleFilters = visibleFilters.filter(
                    (filter) => typeof filter === 'string' || filter instanceof String,
                );

                openFilters = cache.openFilters || {};
                openQuickFilters = cache.openQuickFilters || {};
                crossSchema = cache.crossSchema || {};

                cachedState = {
                    visibleFilters,
                    openFilters,
                    openQuickFilters,
                    quickFilters,
                    crossSchema,
                };
            } else {
                cachedState = {
                    visibleFilters: defaultFilters,
                    openFilters,
                    openQuickFilters,
                    quickFilters: defaultQuickFilters,
                    crossSchema,
                };
            }

            return cachedState;
        }, [cache, defaultQuickFilters, addFixedFilters, getFixedVisibleFilters, defaultFilters]);

        const getMappedSchema = useCallback((schema) => {
            return schema.reduce((obj, current) => {
                if (!obj.schemaByFieldId) obj.schemaByFieldId = {};
                if (!obj.schemaByFieldConfiguration) obj.schemaByFieldConfiguration = {};
                obj.schemaByFieldId[current.id] = current;
                if (current.fieldConfiguration) {
                    obj.schemaByFieldConfiguration[current.fieldConfiguration] = current;
                }
                return obj;
            }, {});
        }, []);

        const mappedVisibleFilters = useMemo(
            () => getMappedFilters(state.visibleFilters, entity.entity),
            [entity, state.visibleFilters],
        );

        const mappedQuickFilters = useMemo(
            () => getMappedFilters(state.quickFilters, entity.entity),
            [entity, state.quickFilters],
        );

        const filtersToIgnore = useMemo(() => {
            let newFiltersToIgnore = ['entityShowSelected', 'onlySelected', 'idProductFamily'];
            // Here we asume that if the entity is not Sales Orders
            // we are using the filter as cross filter and we want to keep it
            if (enableSalesOrdersTypes && entity.entity === SALESORDERS.entity)
                newFiltersToIgnore.push('salesOrderType');
            if (dontIgnoreTheseFilters?.length > 0) {
                newFiltersToIgnore = newFiltersToIgnore.filter(
                    (current) => !dontIgnoreTheseFilters.includes(current),
                );
            }
            return newFiltersToIgnore;
        }, [dontIgnoreTheseFilters, enableSalesOrdersTypes, entity.entity]);

        const newDefaultFilters = useMemo(() => {
            let filters = state.updatedDefaultFilters.filter(
                (current) => !filtersToIgnore.includes(current),
            );

            if (hasCrossFilters) {
                filters = getDefaultFilters({
                    crossSchema: state.crossSchema,
                    defaultFilters: filters,
                    filtersToIgnore,
                });
            }

            return filters;
        }, [
            filtersToIgnore,
            getDefaultFilters,
            hasCrossFilters,
            state.crossSchema,
            state.updatedDefaultFilters,
        ]);

        const init = useCallback(() => {
            const promises = [initFilters(entity)];
            if (hasCrossFilters) promises.push(initCrossFilters(entity));

            Promise.all(promises).then(([generalFilters, crossFilters]) => {
                const cachedState = getStateCached();

                //
                // Init general Filters
                // ------------------------------------------------------------
                const [schema, extraFieldsTab] = generalFilters;

                const { schemaByFieldId } = getMappedSchema(schema);

                let cachedVisible = cachedState?.visibleFilters;
                let finalFilters = cachedVisible ? [...cachedVisible] : [...defaultFilters];

                finalFilters = finalFilters.filter((current) => {
                    return !filtersToIgnore.includes(current);
                });

                // Adding extra field groups
                const groupedExtraFields = extraFieldsTab.reduce((groups, tab) => {
                    groups.push({
                        label: tab.title,
                        options: tab.fields.map((field) => field.id),
                    });
                    return groups;
                }, []);

                // Remove from extraSchema the filters that are only meant to be used or shown as cross-filters
                const finalExtraSchema = [
                    ...(filtersAddButtonSchema || []),
                    ...groupedExtraFields,
                ].reduce((arr, obj) => {
                    obj.options = [...obj.options].filter(
                        (option) =>
                            !schemaByFieldId[option]?.isCrossFilter &&
                            !schemaByFieldId[option]?.showOnlyAsCrossFilter,
                    );
                    arr.push(obj);
                    return arr;
                }, []);

                let payload = {
                    ...cachedState,
                    schema,
                    mappedSchema: schemaByFieldId,
                    visibleFilters: finalFilters,
                    extraSchema: finalExtraSchema,
                    updatedDefaultFilters: [...defaultFilters],
                };

                //
                // Init cross-filters (if available)
                // ------------------------------------------------------------
                if (crossFilters) {
                    const { crossSchema, visibleFilters: finalVisibleFilters } = getCrossFilters({
                        crossFilters,
                        visibleFilters: finalFilters,
                        getMappedSchema,
                        filtersToIgnore,
                    });
                    payload.crossSchema = crossSchema;
                    payload.visibleFilters = finalVisibleFilters;
                }

                dispatch({
                    type: 'init',
                    payload,
                });
            });
        }, [
            initFilters,
            entity,
            hasCrossFilters,
            initCrossFilters,
            getStateCached,
            getMappedSchema,
            defaultFilters,
            filtersAddButtonSchema,
            dispatch,
            filtersToIgnore,
            getCrossFilters,
        ]);

        useEffect(() => {
            if (isFirstLoad.current) {
                init();
                isFirstLoad.current = false;
            }
        }, [init]);

        const getExtraFiltersSchema = useCallback(
            ({ schema, extraSchema, hiddenFilters = [], entity }) => {
                const options = schema.reduce((filters, filter) => {
                    let isStaticFilter = false;

                    if (filter.id === 'pendingConversations' && entity === ACTIVITIES.trueName) {
                        isStaticFilter = true;
                    } else if (filter.id === 'state' && entity === OPPORTUNITIES.trueName) {
                        isStaticFilter = true;
                    }

                    if (!hiddenFilters?.includes(filter.id)) {
                        filters.push({
                            id: filter.id,
                            label: filter.description,
                            forceDisabled: isStaticFilter,
                        });
                    }
                    return filters;
                }, []);

                let groupedOptions = [];

                if (extraSchema && extraSchema.length > 0) {
                    const mappedOptions = options.reduce((obj, current) => {
                        obj[current.id] = current;
                        return obj;
                    }, {});

                    groupedOptions = extraSchema.reduce((result, current) => {
                        let group = {
                            label: current.label,
                            id: current.label,
                        };

                        let options = current.options.reduce((arr, option) => {
                            if (mappedOptions[option]) {
                                arr.push(mappedOptions[option]);
                                mappedOptions[option].included = true;
                            }
                            return arr;
                        }, []);

                        group.options = options;
                        result.push(group);

                        return result;
                    }, []);
                }

                let finalOptions = options;
                if (groupedOptions && groupedOptions.length > 0) finalOptions = groupedOptions;

                return finalOptions;
            },
            [],
        );

        const extraFiltersSchema = useMemo(() => {
            return getExtraFiltersSchema({
                schema: state.schema,
                extraSchema: state.extraSchema,
                hiddenFilters,
                entity,
            });
        }, [getExtraFiltersSchema, state.schema, state.extraSchema, hiddenFilters, entity]);

        const saveNewCache = useCallback(
            (newCache, partial) => {
                const compareFields = [
                    'openQuickFilters',
                    'visibleFilters',
                    'openFilters',
                    'quickFilters',
                ];
                const payload = compareFields.reduce((obj, field) => {
                    obj[field] = newCache[field] || state[field];
                    return obj;
                }, {});
                return saveCache(payload, partial);
            },
            [state, saveCache],
        );

        const formatProbabilityToShow = useCallback((newValue) => {
            newValue = [newValue.from * 10, newValue.to * 10];
            return newValue;
        }, []);

        const formatProbabilityToSave = useCallback((newValue) => {
            newValue = { from: newValue?.from / 10 || 0, to: newValue?.to / 10 || 0 };
            return newValue;
        }, []);

        const rangeFields = useMemo(
            () => ['date', 'integer', 'decimal', 'currency', 'percent', 'probability'],
            [],
        );

        const formatRangeValueToShow = useCallback(
            (value, field) => {
                if (!value || !field) return [];
                if (Array.isArray(value)) return value;
                let formattedValue;
                if (field.dataType === 'probability') {
                    formattedValue = formatProbabilityToShow(value);
                } else if (field.dataType === 'date') {
                    const from = value['from'];
                    const to = value['to'];
                    formattedValue = [
                        from ? moment.utc(from).local().toDate() : '',
                        to ? moment.utc(to).local().toDate() : '',
                    ];
                } else {
                    formattedValue = [value['from'], value['to']];
                }
                return formattedValue;
            },
            [formatProbabilityToShow],
        );

        const formatRangeValueToSave = useCallback(
            (value, field) => {
                let defaultFrom = '';
                let defaultTo = '';
                if (field?.inputProps?.min || !isNaN(field?.inputProps?.min)) {
                    defaultFrom = field.inputProps.min;
                }
                if (field?.inputProps?.max || !isNaN(field?.inputProps?.max)) {
                    defaultTo = field.inputProps.max;
                }
                let formattedValue = {
                    from: value?.[0] || defaultFrom,
                    to: value?.[1] || defaultTo,
                };
                if (field.dataType === 'probability') {
                    formattedValue = formatProbabilityToSave(formattedValue);
                }
                return formattedValue;
            },
            [formatProbabilityToSave],
        );

        const relatedEntity = useMemo(() => {
            switch (true) {
                case inConversations(location):
                    return CONVERSATIONS;
                default:
                    return null;
            }
        }, [location]);

        const handleSubfilters = useCallback(
            ({ values, oldValues, filter, crossEntity }) => {
                if (!values) return;

                const { id: realID } = getRealID(filter);
                const crossId = getCrossID(filter, crossEntity);

                // Currently we only have subfilters for the activityType filter,
                // enhance (or consider refactor) in the future if more subfilters are enabled
                switch (realID) {
                    case 'activityType':
                        const newAddedValue = [...values].pop();
                        const toggleNewSubfilter = newAddedValue && !oldValues?.length;
                        const isNewSubfilter =
                            oldValues.filter((value) => value.value === newAddedValue).length === 0;

                        if (toggleNewSubfilter || isNewSubfilter) {
                            const newAddedSubfilter = mappedActivityTypeIds[newAddedValue];
                            if (newAddedSubfilter) {
                                onToggleFilter({
                                    filter: getCrossID(newAddedSubfilter, crossEntity),
                                    hasSubfilters: false,
                                    parentFilter: crossId,
                                    overrideState: state,
                                });
                            }
                        }
                        break;
                    default:
                        break;
                }
            },
            [onToggleFilter, state],
        );

        const onChangeFilter = useCallback(
            ({ filter, value, info, crossEntity }) => {
                let completeValues = null;
                let newValue = null;

                const currentOperators = !!crossEntity
                    ? crossOperators?.[crossEntity] || {}
                    : operators;
                const hasOperator =
                    getHasAdvancedFilters(crossEntity) &&
                    currentOperators &&
                    !!currentOperators?.[filter.id];
                const isCrossFilter = !!crossEntity;

                if (rangeFields.includes(filter.dataType)) {
                    value = formatRangeValueToSave(value, filter);
                }

                if (value && Array.isArray(value)) {
                    completeValues = value.map((current) => {
                        if (!newValue) newValue = [];
                        newValue.push(current.value || current);
                        return {
                            value: current.value || current,
                            label: current.label,
                            idParent: current.idParent || current.idparent,
                        };
                    });
                } else {
                    newValue = value?.value || value;
                    completeValues = value;
                }

                if (filter.customFormatValueToSave) {
                    newValue = filter.customFormatValueToSave(newValue);
                }

                if (filter.customFormatCompleteValuesToSave) {
                    completeValues = filter.customFormatCompleteValuesToSave(
                        completeValues,
                        filter,
                    );
                }

                if (useQuickFilters) {
                    logEvent({ event: entity.trueName, functionality: 'quickFilter' });
                    isCrossFilter &&
                        logEvent({ event: entity.trueName, functionality: 'relatedEntityFilter' });
                    hasOperator &&
                        logEvent({ event: entity.trueName, functionality: 'advancedFilters' });
                } else {
                    if (newValue && newValue?.length) {
                        logEvent({
                            event: entity.trueName,
                            functionality: isCrossFilter ? 'relatedEntityFilter' : 'filter',
                        });
                        hasOperator &&
                            logEvent({ event: entity.trueName, functionality: 'advancedFilters' });
                    }
                }

                const inputAttrs = filter.inputAttrs || null;

                if (
                    (!newValue ||
                        (inputAttrs?.minSelection && inputAttrs?.minSelection > newValue.length)) &&
                    inputAttrs?.backupSelection
                ) {
                    newValue = inputAttrs.backupSelection?.value || null;
                    completeValues = inputAttrs.backupSelection?.completeValues || null;
                }

                const payload = {
                    entity,
                    filter,
                    value: newValue,
                    refresh: undefined,
                    completeValues,
                    isEntityList: null,
                    info,
                    isPreload: false,
                    relatedEntity,
                    crossEntity,
                };

                changeFilter(payload);

                if (filter.hasOwnProperty('subfilterKey')) {
                    const crossId = getCrossID(filter.id, crossEntity);
                    handleSubfilters({
                        values: newValue,
                        oldValues: values[crossId] || [],
                        filter: filter.id,
                        crossEntity,
                    });
                }
            },
            [
                crossOperators,
                operators,
                getHasAdvancedFilters,
                rangeFields,
                useQuickFilters,
                handleSubfilters,
                values,
                changeFilter,
                entity,
                relatedEntity,
                formatRangeValueToSave,
            ],
        );

        const onClearFilters = useCallback(() => {
            if (filters.customView) {
                sessionStorage.removeItem(filters.customView.value.sessionStorageLabel);
            }

            if (clearSpecificFilter) {
                clearSpecificFilter();
            }

            if (useQuickFilters) {
                logEvent({ event: entity.trueName, functionality: 'quickClearFilter' });
            } else {
                logEvent({ event: entity.trueName, functionality: 'clearFilter' });
            }
            clearFilters({
                entity,
                isAPurge: false,
                refresh: true,
                isFirstLoad: true,
                isEntityList: true,
                relatedEntity,
                clearAdvancedAndCrossFilters: true,
            });
        }, [filters, clearSpecificFilter, useQuickFilters, clearFilters, entity, relatedEntity]);

        const onResetVisibles = useCallback(() => {
            dispatch({ type: 'quickFilters', payload: defaultQuickFilters });

            saveNewCache({
                quickFilters: defaultQuickFilters,
            });

            logEvent({
                event: entity.trueName,
                functionality: 'resetFilterSelection',
            });
        }, [dispatch, defaultQuickFilters, saveNewCache, entity.trueName]);

        const onAddFilter = useCallback(
            (filters) => {
                const newVisibleFilters = [...filters];
                const newQuickFilters = state.quickFilters.filter((filter) =>
                    filters.includes(filter),
                );
                dispatch({ type: 'visibleFilters', payload: newVisibleFilters });
                dispatch({ type: 'quickFilters', payload: newQuickFilters });
                saveNewCache({
                    visibleFilters: newVisibleFilters,
                    quickFilters: newQuickFilters,
                });
            },
            [dispatch, saveNewCache, state.quickFilters],
        );

        const onDeleteFilter = useCallback(
            (filter) => {
                const newVisibleFilters = state.visibleFilters.filter((f) => f !== filter);
                const newQuickFilters = state.quickFilters.filter((f) => f !== filter);
                const newOpenFilters = Object.keys(state.openFilters).reduce((obj, key) => {
                    if (key === filter) return obj;
                    obj[key] = state.openFilters[key];
                    return obj;
                }, {});
                const newOpenQuickFilters = Object.keys(state.openQuickFilters).reduce(
                    (obj, key) => {
                        if (key === filter) return obj;
                        obj[key] = state.openQuickFilters[key];
                        return obj;
                    },
                    {},
                );

                logEvent({ event: entity.trueName, functionality: 'deleteFilter' });

                const config = {
                    openFilters: newOpenFilters,
                    openQuickFilters: newOpenQuickFilters,
                    visibleFilters: newVisibleFilters,
                    quickFilters: newQuickFilters,
                };

                dispatch({ type: 'saveConfig', payload: config });

                saveNewCache(config).finally(() => {
                    const { id: filterId, entity: crossEntity } = getRealID(filter);

                    const finalCrossEntity =
                        crossEntity && !SENT_TO_BE_AS_STANDARD_FILTERS.includes(filterId)
                            ? crossEntity
                            : null;

                    const filterObj = finalCrossEntity
                        ? crossFilters?.[finalCrossEntity]?.[filterId]
                        : filters[filterId];

                    const finalOperators = finalCrossEntity
                        ? crossOperators?.[finalCrossEntity]
                        : operators;

                    const operatorsObj = finalOperators?.[filterId];

                    // GOKU-8801
                    // If we remove a crossfilter we should disable the "force default cross-filters" promo feature
                    if (!!crossEntity) {
                        disableForceCrossFilters(entity);
                    }

                    if (filterObj) {
                        onChangeFilter({
                            filter: filterObj,
                            value: null,
                            crossEntity: finalCrossEntity,
                        });
                    }

                    if (operatorsObj) {
                        const { [filterId]: undefined, ...operatorsRest } = finalOperators;
                        changeAdvancedFilter({
                            entity,
                            operators: operatorsRest,
                            shouldUpdateEntity: !filterObj,
                            crossEntity: finalCrossEntity,
                        });
                    }
                });
            },
            [
                state,
                dispatch,
                entity,
                saveNewCache,
                crossFilters,
                filters,
                crossOperators,
                operators,
                onChangeFilter,
                changeAdvancedFilter,
                disableForceCrossFilters,
            ],
        );

        const onDeleteMultipleFilters = useCallback(
            (filters) => {
                deleteMultipleFilters({ entity, filters });
            },
            [entity, deleteMultipleFilters],
        );

        const onToggleFilter = useCallback(
            ({ filter, hasSubfilters = false, parentFilter = null, overrideState }) => {
                const type = !!useQuickFilters ? 'openQuickFilters' : 'openFilters';
                const finalState = overrideState || state;
                const openFilters = finalState[type] || {};
                let newOpenFilters = {};

                // Helper function to set the subfilters values
                const toggleSubfilters = (subfilters = {}, filter = null) => {
                    const newSubfilters = Object.entries(subfilters).reduce((obj, [key, value]) => {
                        obj[key] = key === filter ? !value : false; // Toggle the specified subfilter and close others
                        return obj;
                    }, {});
                    if (filter && !newSubfilters.hasOwnProperty(filter)) {
                        newSubfilters[filter] = true;
                    }
                    return newSubfilters;
                };

                if (!parentFilter) {
                    // Toggle main level filters
                    newOpenFilters = Object.entries(openFilters).reduce((obj, [key, value]) => {
                        let newValue;

                        switch (true) {
                            case key === filter && hasSubfilters:
                                newValue = { ...value, open: !value.open };
                                break;
                            case key === filter && !hasSubfilters:
                                newValue = !value;
                                break;
                            default:
                                newValue =
                                    typeof value === 'boolean' ? false : { ...value, open: false };
                                break;
                        }

                        obj[key] = newValue;

                        return obj;
                    }, {});
                    if (!newOpenFilters.hasOwnProperty(filter)) {
                        newOpenFilters[filter] = hasSubfilters
                            ? { open: true, subfilters: {} }
                            : true;
                    }
                } else {
                    // Toggle subfilters
                    newOpenFilters = Object.entries(openFilters).reduce((obj, [key, value]) => {
                        if (key === parentFilter) {
                            obj[key] = {
                                open: true,
                                subfilters: toggleSubfilters(value.subfilters, filter),
                            };
                        } else {
                            obj[key] =
                                typeof value === 'boolean' ? false : { ...value, open: false };
                        }
                        return obj;
                    }, {});
                    if (!newOpenFilters.hasOwnProperty(parentFilter)) {
                        newOpenFilters[parentFilter] = {
                            open: true,
                            subfilters: toggleSubfilters({}, filter),
                        };
                    }
                }

                saveNewCache(
                    {
                        [type]: newOpenFilters,
                    },
                    !!useQuickFilters,
                );
            },
            [useQuickFilters, state, saveNewCache],
        );

        const formatViews = useMemo(() => {
            const viewsName = entity.views;
            if (!views?.length > 0) return [];
            return views.reduce((obj, view) => {
                if (view.entity === viewsName) {
                    obj.push({
                        value: parseInt(view.id, 10),
                        label: view.name,
                        item: view,
                    });
                }
                return obj;
            }, []);
        }, [views, entity]);

        const formatCustomView = useCallback((customView) => {
            if (!customView) return null;
            return {
                value: {
                    value: customView.SelectFilterId,
                    label: customView.label,
                    item: {
                        id: customView.SelectFilterId,
                        isPublic: false,
                    },
                },
                label: customView.label,
                id: 'customView',
                hideForCount: false,
            };
        }, []);

        const onChangeView = useCallback(
            (view) => {
                onChangeFilter({
                    filter: {
                        id: 'idView',
                        dataType: 'view',
                        hideForCount: false,
                    },
                    value: view,
                });
                logEvent({ event: entity.trueName, functionality: 'view' });
            },
            [onChangeFilter, entity],
        );

        const onChangeCustomView = useCallback(
            (view) => {
                sessionStorage.removeItem(filters.customView.value.sessionStorageLabel);
                onDeleteFilter(filters.customView.id);
                onChangeFilter({
                    filter: {
                        id: 'idView',
                        dataType: 'view',
                        hideForCount: false,
                    },
                    value: view,
                });
            },
            [filters, onChangeFilter, onDeleteFilter],
        );

        const onDeleteCustomView = useCallback(() => {
            sessionStorage.removeItem(filters.customView.value.sessionStorageLabel);
            onDeleteFilter(filters.customView.id);
            return Promise.resolve();
        }, [filters, onDeleteFilter]);

        const onSaveView = useCallback(
            ({ name, isPrivate }) => {
                return new Promise((resolve, reject) => {
                    const isPublic = !isPrivate;
                    createView(entity, {
                        name,
                        isPublic,
                    })
                        .then((view) => {
                            clearFilters({
                                entity,
                                isAPurge: false,
                                refresh: false,
                                clearAdvancedAndCrossFilters: true,
                            });

                            onChangeView({
                                value: view.id,
                                label: view.name,
                                item: view,
                            });
                            successToast({ text: getLiteral('label_view_successfully_created') });
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            errorToast({ text: getLiteral('error_generalerror') });
                            reject();
                        });
                });
            },
            [createView, entity, clearFilters, onChangeView],
        );

        const onDeleteView = useCallback(
            (view) => {
                return new Promise((resolve, reject) => {
                    removeView(view.item)
                        .then(() => {
                            successToast({ text: getLiteral('label_view_successfully_deleted') });
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            errorToast({ text: getLiteral('error_generalerror') });
                            reject();
                        });

                    onChangeView(null);
                });
            },
            [removeView, onChangeView],
        );

        const isFuzzy = useCallback((list, field) => {
            if (!list) return false;
            return (
                FuzzyList.includes(list.toLowerCase()) ||
                getBackendBoolean(field.fuzzySearchValueList)
            );
        }, []);

        const fetchOptions = useCallback(
            ({ text, list, field, defaultSearch }) => {
                return getFuzzy({ field, list, text, feature: entity.entity });
            },
            [getFuzzy, entity],
        );

        const loadFuzzyOptions = useCallback(
            (field) => {
                return (text) => {
                    return new Promise((resolve) => {
                        if (!field.inputAttrs) return resolve([]);
                        const cachedOptionsKey = `${field.inputAttrs.list}__${text}`;
                        if (cachedOptions.current[cachedOptionsKey])
                            return resolve(cachedOptions.current[cachedOptionsKey]);
                        clearTimeout(debounce.current);
                        debounce.current = setTimeout(() => {
                            fetchOptions({ text, ...field.inputAttrs })
                                .then((options) => {
                                    let newOptions = options;
                                    if (field.beforeRenderValueList) {
                                        newOptions = field.beforeRenderValueList(
                                            newOptions,
                                            field?.inputAttrs?.list,
                                        );
                                    }
                                    if (field.groupFunction) {
                                        newOptions = field.groupFunction(newOptions);
                                    }
                                    cachedOptions.current[text] = newOptions;
                                    resolve(newOptions);
                                })
                                .catch(console.error);
                        }, 500);
                    });
                };
            },
            [fetchOptions],
        );

        const loadOptions = useCallback(
            (field) => () => {
                if (!field?.inputAttrs?.list) return Promise.resolve([]);
                return new Promise((resolve, reject) => {
                    getList(field.inputAttrs.list)
                        .then((options) => {
                            let newOptions = options;
                            if (field.beforeRenderValueList) {
                                newOptions = field.beforeRenderValueList(
                                    newOptions,
                                    field?.inputAttrs?.list,
                                );
                            }
                            if (field.groupFunction) {
                                newOptions = field.groupFunction(newOptions);
                            }
                            resolve(newOptions);
                        })
                        .catch(reject);
                });
            },
            [getList],
        );

        const getFieldFormatted = useCallback(
            (field, crossEntityOperators) => {
                const newField = { ...field };
                if (!newField.inputProps) newField.inputProps = {};
                if (field.hint) newField.inputProps.placeholder = field.hint;
                switch (field.dataType) {
                    case 'bool':
                        newField.inputProps.options = newField?.inputAttrs?.options || [
                            { label: getLiteral('common_yes'), value: '1' },
                            { label: getLiteral('common_no'), value: '2' },
                        ];
                        break;
                    case 'probability':
                        newField.inputProps = {
                            min: 0,
                            max: 100,
                        };
                        break;
                    case 'singleValueList':
                    case 'relatedValueList':
                    case 'multipleValueList':
                        if (field?.generateOptions) {
                            newField.inputProps.options = field.generateOptions() || [];
                        } else if (
                            field?.inputAttrs?.list &&
                            isFuzzy(field?.inputAttrs?.list, field)
                        ) {
                            newField.inputProps.isFuzzy = true;
                            newField.inputProps.isMulti = newField.inputAttrs.hasOwnProperty(
                                'isMulti',
                            )
                                ? newField.inputAttrs.isMulti
                                : true;
                            newField.inputProps.loadOptions = loadFuzzyOptions(field);
                            newField.inputProps.defaultOptions = true;
                            newField.inputProps.defaultSearch =
                                field.inputAttrs?.defaultSearch || '';
                        } else {
                            newField.inputProps.isMulti = true;
                            if (field?.inputAttrs?.list) {
                                newField.inputProps.loadOptions = loadOptions(field);
                            }
                        }

                        if (field?.inputAttrs?.filterOption) {
                            newField.inputProps.overrides = {
                                'react-select': {
                                    filterOption: field?.inputAttrs?.filterOption,
                                },
                            };
                        }

                        if (!newField.inputProps.placeholder) {
                            newField.inputProps.placeholder = getLiteral('label_selectone');
                        }

                        if (newField.inputProps.isMulti && !newField.inputProps.isFuzzy) {
                            newField.inputProps.selectAllLabel = getLiteral('label_selectall');
                        }

                        // Filtering options if dependent of principal filter
                        if (
                            standardFieldsSchemaMap?.[newField.fieldConfiguration]
                                ?.strParentField === principalFilter
                        ) {
                            newField.inputProps.overrides = {
                                'react-select': {
                                    filterOption: ({ data }) => {
                                        if (!filters || !data) return true;
                                        let principal = Object.keys(filters).find(
                                            (filterKey) =>
                                                filters[filterKey]?.fieldConfiguration ===
                                                principalFilter,

                                            {},
                                        );
                                        if (principal) principal = filters[principal]?.value?.[0];

                                        return (
                                            (principal && principal === data?.idparent) ||
                                            !principal
                                        );
                                    },
                                },
                            };
                        }

                        if (field.inputAttrs?.overrides) {
                            if (newField.inputProps.overrides) {
                                newField.inputProps.overrides = {
                                    ...newField.inputProps.overrides,
                                    ...field.inputAttrs.overrides,
                                    'react-select': {
                                        ...newField.inputProps.overrides['react-select'],
                                        ...(field.inputAttrs.overrides?.['react-select'] || {}),
                                    },
                                };
                            } else {
                                newField.inputProps.overrides = {
                                    ...field.inputAttrs.overrides,
                                };
                            }
                        }

                        if (field.inputAttrs?.className) {
                            newField.inputProps.className = field.inputAttrs.className;
                        }

                        if (field.inputAttrs?.getClassName) {
                            newField.inputProps.className = field.inputAttrs.getClassName();
                        }

                        break;
                    case 'text':
                        if (!newField?.inputAttrs?.placeholder) {
                            newField.inputProps.placeholder = getLiteral('placeholder_search');
                        }
                }

                // Set Quickfilters visual hint value if advanced filter is set
                const finalOperators =
                    !!crossEntityOperators && !field.isCrossFilter
                        ? crossEntityOperators
                        : operators;
                if (finalOperators && finalOperators[field.id])
                    newField.operator = finalOperators[field.id];

                return {
                    ...newField,
                };
            },
            [
                isFuzzy,
                standardFieldsSchemaMap,
                principalFilter,
                loadFuzzyOptions,
                loadOptions,
                filters,
                operators,
            ],
        );

        const getFieldsFormatted = useCallback(
            (fields, crossEntityOperators) => {
                const formattedField = getFieldFormatted(fields, crossEntityOperators);
                return [formattedField];
            },
            [getFieldFormatted],
        );

        const toggleQuickFilter = useCallback(
            (filter) => {
                const alreadyExists = state.quickFilters.includes(filter);
                const newQuickFilters = alreadyExists
                    ? state.quickFilters.filter((i) => i !== filter)
                    : [...state.quickFilters, filter];
                dispatch({ type: 'quickFilters', payload: newQuickFilters });
                saveNewCache({
                    quickFilters: newQuickFilters,
                });

                logEvent({
                    event: entity.trueName,
                    functionality: alreadyExists ? 'unpinQuickFilter' : 'pinQuickFilter',
                });
            },
            [dispatch, entity.trueName, saveNewCache, state.quickFilters],
        );

        const onReOrder = useCallback(
            (startIndex, endIndex) => {
                const result = [...state.quickFilters];
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);
                dispatch({ type: 'quickFilters', payload: result });
                saveNewCache({
                    quickFilters: result,
                });

                logEvent({
                    event: entity.trueName,
                    functionality: 'orderQuickFilter',
                });
            },
            [dispatch, entity.trueName, saveNewCache, state.quickFilters],
        );

        const onClose = useCallback(() => {
            toggleFilters(entity, false);
            intercomHideAndShow();
            userflowHideAndShow('show');
        }, [entity, toggleFilters]);

        const onDrag = useCallback(
            (item) => {
                setFilterDragging(entity, item.draggableId);
            },
            [entity, setFilterDragging],
        );

        const onDrop = useCallback(
            (item) => {
                // Keep state for eye tracking (ux)
                setTimeout(() => {
                    setFilterDragging(entity, null);
                }, 750);
            },
            [entity, setFilterDragging],
        );

        const getSubfilterSchema = useCallback(
            ({ crossEntity, filter, key, schema }) => {
                const isCrossFilter = !!crossEntity;

                const entityFilters = isCrossFilter
                    ? crossFilters?.[crossEntity] || {}
                    : filters || {};

                const parentFilterValue = entityFilters[filter]?.value;

                if (!parentFilterValue?.length) return [];

                const subfilterSchema = Object.values(schema).reduce((arr, item) => {
                    const { description, id, isSubFilter, [key]: parentFilterId } = item;

                    if (
                        isSubFilter &&
                        parentFilterId &&
                        parentFilterValue.includes(parentFilterId)
                    ) {
                        const subfilter = {
                            fields: [...getFieldsFormatted(item)],
                            id,
                            label: description,
                            // We can't pin or remove them because they depend on the parent filter
                            isPinnable: false,
                            isRemovable: false,
                        };

                        if (isCrossFilter) {
                            subfilter.entity = crossEntity;
                            subfilter.crossId = getCrossID(id, crossEntity);
                        }

                        arr.push(subfilter);
                    }

                    return arr;
                }, []);

                return subfilterSchema;
            },
            [crossFilters, filters, getFieldsFormatted],
        );

        const formatFinalSchema = useMemo(() => {
            if (!state.visibleFilters || state.visibleFilters.length === 0) return [];
            return state.visibleFilters
                .filter((filter) => !state.quickFilters.includes(filter))
                .reduce((arr, current) => {
                    if (!state.mappedSchema[current]) return arr;
                    if (hiddenFilters.includes(current)) return arr;
                    // Use this if we want to allow removing the filter or not according if it is default or not.
                    // const isDefault = newDefaultFilters?.includes(current) || false;

                    const mappedSchema = state.mappedSchema[current];

                    let isRemovable = true;
                    if (mappedSchema?.hasOwnProperty('isRemovable')) {
                        isRemovable = mappedSchema.isRemovable;
                    }

                    let isPinnable = true;
                    if (mappedSchema?.hasOwnProperty('isPinnable')) {
                        isPinnable = mappedSchema.isPinnable;
                    }

                    let subfilterSchema;
                    if (mappedSchema?.hasOwnProperty('subfilterKey')) {
                        subfilterSchema = getSubfilterSchema({
                            filter: current,
                            key: mappedSchema.subfilterKey,
                            schema: state.mappedSchema,
                        });
                    }

                    arr.push({
                        label: mappedSchema.description,
                        id: mappedSchema.id,
                        fields: [...getFieldsFormatted(mappedSchema)],
                        isRemovable,
                        isPinnable,
                        subfilterSchema,
                    });

                    return arr;
                }, []);
        }, [state, hiddenFilters, getFieldsFormatted, getSubfilterSchema]);

        const formatFinalQuickSchema = useMemo(() => {
            if (!state.quickFilters || state.quickFilters.length === 0) return [];
            return state.quickFilters.reduce((arr, current) => {
                const splitFilter = current.split(CROSS_FILTER_ID_SEPARATOR);
                const isCrossFilter = splitFilter.length > 1;

                let crossEntity, crossID, crossEntityOperators;

                if (isCrossFilter) {
                    [crossEntity, crossID] = splitFilter;
                    crossEntityOperators = crossOperators?.[crossEntity] || {};
                }

                const mappedSchema = isCrossFilter
                    ? state.crossSchema[crossEntity]?.mappedSchema
                    : state.mappedSchema;

                const finalCurrent = isCrossFilter ? crossID : current;

                if (!mappedSchema?.[finalCurrent]) return arr;
                if (hiddenFilters.includes(finalCurrent)) return arr;

                let isRemovable = true;
                if (mappedSchema[finalCurrent]?.hasOwnProperty('isRemovable')) {
                    isRemovable = mappedSchema[finalCurrent].isRemovable;
                }

                let isPinnable = true;
                if (mappedSchema[finalCurrent]?.hasOwnProperty('isPinnable')) {
                    isPinnable = mappedSchema[finalCurrent].isPinnable;
                }

                let isCrossFilterProp = false;
                if (mappedSchema[finalCurrent]?.hasOwnProperty('isCrossFilter')) {
                    isCrossFilterProp = mappedSchema[finalCurrent].isCrossFilter;
                }

                let subfilterSchema;
                if (mappedSchema[finalCurrent]?.hasOwnProperty('subfilterKey')) {
                    subfilterSchema = getSubfilterSchema({
                        crossEntity,
                        filter: finalCurrent,
                        key: mappedSchema[finalCurrent].subfilterKey,
                        schema: mappedSchema,
                    });
                }

                const filterObj = {
                    label: mappedSchema[finalCurrent].description,
                    id: mappedSchema[finalCurrent].id,
                    fields: [
                        ...getFieldsFormatted(mappedSchema[finalCurrent], crossEntityOperators),
                    ],
                    isRemovable,
                    isPinnable,
                    isCrossFilter: isCrossFilterProp,
                    subfilterSchema,
                };

                if (isCrossFilter) {
                    filterObj.subLabel = state.crossSchema[crossEntity]?.title;
                    filterObj.entity = crossEntity;
                    filterObj.crossId = getCrossID(mappedSchema[finalCurrent].id, crossEntity);
                }

                arr.push(filterObj);

                return arr;
            }, []);
        }, [state, hiddenFilters, getFieldsFormatted, crossOperators, getSubfilterSchema]);

        const formatFinalCrossSchema = useMemo(() => {
            if (!state.crossSchema || Object.entries(state.crossSchema).length === 0) return {};
            return getFinalCrossSchema({
                crossSchema: state.crossSchema,
                quickfilters: mappedQuickFilters,
                visibleFilters: mappedVisibleFilters,
                getFieldsFormatted,
                getSubfilterSchema,
            });
        }, [
            getFieldsFormatted,
            getFinalCrossSchema,
            getSubfilterSchema,
            mappedQuickFilters,
            mappedVisibleFilters,
            state.crossSchema,
        ]);

        const getValues = useCallback(
            ({ filters, entity, mappedSchema, crossEntity }) => {
                const finalEntity = crossEntity || entity;
                let newFilters = translateFollowingItemForWeb(filters, finalEntity);

                const newValues = Object.keys(newFilters).reduce((obj, key) => {
                    if (filtersToIgnore.includes(key)) return obj;

                    let newFilter = filters[key];

                    newFilter = translateExtraBoolean(newFilter, finalEntity);

                    let value = newFilter.completeValues || newFilter.value;
                    if (rangeFields.includes(newFilter.dataType)) {
                        const field = mappedSchema[key] || null;
                        value = formatRangeValueToShow(value, field);
                    }

                    const finalKey = getCrossID(key, crossEntity?.entity);

                    obj[finalKey] = value;
                    return obj;
                }, {});

                return newValues;
            },
            [filtersToIgnore, formatRangeValueToShow, rangeFields],
        );

        const values = useMemo(() => {
            if (!state.mappedSchema) return {};
            if (!hasCrossFilters && (!filters || Object.entries(filters).length === 0)) return {};

            let finalValues = getValues({ filters, entity, mappedSchema: state.mappedSchema });

            if (crossFilters && Object.entries(crossFilters).length > 0) {
                finalValues = {
                    ...finalValues,
                    ...getCrossValues({ crossFilters, crossSchema: state.crossSchema, getValues }),
                };
            }

            return finalValues;
        }, [
            hasCrossFilters,
            state.mappedSchema,
            state.crossSchema,
            filters,
            getValues,
            entity,
            crossFilters,
            getCrossValues,
        ]);

        const useViews = useMemo(() => {
            if (!useViewsInFilters && !useViewsInMenu) return false;
            return true;
        }, [useViewsInFilters, useViewsInMenu]);

        const hasFiltersActive = useMemo(() => {
            if (!filters) return false;
            let { entityShowSelected, matchingName, idProductFamily, ...otherFilters } = filters;
            if (enableSalesOrdersTypes) {
                // Removing salesOrderType
                otherFilters = Object.keys(otherFilters).reduce((obj, filterKey) => {
                    if (filterKey === 'salesOrderType') return obj;
                    obj[filterKey] = otherFilters[filterKey];
                    return obj;
                }, {});
            }
            return (
                Object.entries(otherFilters).length > 0 || getHasCrossFiltersActive(crossFilters)
            );
        }, [filters, crossFilters, enableSalesOrdersTypes, getHasCrossFiltersActive]);

        const handleChangeOperator = useCallback(
            ({ filter, value, crossEntity }) => {
                const finalCrossEntity = !filter.isCrossFilter ? crossEntity : null;
                onChangeOperator({
                    filter,
                    value,
                    values,
                    useQuickFilters,
                    crossEntity: finalCrossEntity,
                });
            },
            [onChangeOperator, useQuickFilters, values],
        );

        const filtersProps = useMemo(() => {
            let finalProps = {};

            const commonProps = {
                crossOperators,
                customView: formatCustomView(values?.customView || null),
                entity,
                getAllowedOperators,
                getHasAdvancedFilters,
                onChange: onChangeFilter,
                onChangeCustomView,
                onChangeOperator: handleChangeOperator,
                onChangeView,
                onClearAll: onClearFilters,
                operators,
                selectedView: values?.customView || values?.idView || null,
                values,
                views: formatViews,
            };

            if (useQuickFilters) {
                finalProps = {
                    className,
                    crossLabels,
                    filterDragging,
                    filters: state.quickFilters,
                    getCanClearAll,
                    hasFiltersActive,
                    license,
                    onToggle: onToggleFilter,
                    resetFilter: () => init(true),
                    schema: formatFinalQuickSchema,
                    toggled: state.openQuickFilters,
                    useQuickView,
                    ...commonProps,
                };
            } else {
                finalProps = {
                    canManagePrivateViews: viewsPrivateManagement,
                    canManagePublicViews: viewsPublicManagement,
                    crossSchema: formatFinalCrossSchema,
                    defaultFilters: newDefaultFilters,
                    extraSchema: extraFiltersSchema,
                    isOpen,
                    onClose,
                    onConfirmAdd: onAddFilter,
                    onDelete: onDeleteFilter,
                    onDeleteCustomView,
                    onDeleteMultiple: onDeleteMultipleFilters,
                    onDeleteView,
                    onDrag,
                    onDrop,
                    onReOrder,
                    onResetVisibles,
                    onSaveView: useViews && onSaveView ? onSaveView : undefined,
                    onToggle: onToggleFilter,
                    quickFilters: state.quickFilters,
                    quickSchema: formatFinalQuickSchema,
                    schema: formatFinalSchema,
                    showAdd: true,
                    toggled: state.openFilters,
                    toggleQuickFilter,
                    useDrawer,
                    useViewsInFilters,
                    useViewsInMenu,
                    visibleFilters: state.visibleFilters,
                    ...commonProps,
                    ...props,
                };

                if (hasCrossFilters) {
                    finalProps = {
                        ...finalProps,
                        ...getModalOptionsSchema({
                            crossSchema: state.crossSchema,
                            extraSchema: extraFiltersSchema,
                            getExtraFiltersSchema,
                            hiddenFilters: filtersToIgnore,
                        }),
                    };
                }
            }

            return finalProps;
        }, [
            className,
            crossLabels,
            crossOperators,
            entity,
            extraFiltersSchema,
            filterDragging,
            filtersToIgnore,
            formatCustomView,
            formatFinalCrossSchema,
            formatFinalQuickSchema,
            formatFinalSchema,
            formatViews,
            getAllowedOperators,
            getCanClearAll,
            getExtraFiltersSchema,
            getHasAdvancedFilters,
            getModalOptionsSchema,
            handleChangeOperator,
            hasCrossFilters,
            hasFiltersActive,
            init,
            isOpen,
            license,
            newDefaultFilters,
            onAddFilter,
            onChangeCustomView,
            onChangeFilter,
            onChangeView,
            onClearFilters,
            onClose,
            onDeleteCustomView,
            onDeleteFilter,
            onDeleteMultipleFilters,
            onDeleteView,
            onDrag,
            onDrop,
            onReOrder,
            onResetVisibles,
            onSaveView,
            onToggleFilter,
            operators,
            props,
            state.crossSchema,
            state.openFilters,
            state.openQuickFilters,
            state.quickFilters,
            state.visibleFilters,
            toggleQuickFilter,
            useDrawer,
            useQuickFilters,
            useQuickView,
            useViews,
            useViewsInFilters,
            useViewsInMenu,
            values,
            viewsPrivateManagement,
            viewsPublicManagement,
        ]);

        return useQuickFilters ? <QuickFilters {...filtersProps} /> : <Filters {...filtersProps} />;
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(NewEntityFilters);
