import React, { memo } from 'react';
import EntityDetail from 'containers/components/EntityDetail';
import EntityCrud from 'containers/components/EntityCrud';
import DashboardGrid from './DashboardGrid';
import DashboardTopBar from './components/DashboardTopBar';

const DailyDashboard = memo(() => {
    return (
        <div className="fm-daily-dashboard">
            <DashboardTopBar />
            <DashboardGrid />
            <EntityCrud />
            <EntityDetail openAllDetails />
        </div>
    );
});

export default DailyDashboard;
