import {
    ENTITY_DETAIL_OPEN,
    ENTITY_DETAIL_CLOSE,
    ENTITY_DETAIL_INIT,
    ENTITY_DETAIL_INIT_SUCCESS,
    ENTITY_DETAIL_INIT_ERROR,
    ENTITY_DETAIL_RESET,
    ENTITY_DETAIL_SET_NEXT_DETAIL,
    ENTITY_DETAIL_RESET_NEXT_DETAIL,
    ENTITY_CRUD_INIT_WORKFLOW,
    ENTITY_CRUD_CLOSE,
    ENTITY_DETAIL_CHANGE_SECTION,
    ENTITY_DETAIL_LOADING,
    ENTITY_UPDATE_LOCALLY,
    ENTITY_UPDATE_EXTRA,
    ENTITY_DETAIL_SET_TABS,
} from 'constants/ActionTypes';
import Context from 'managers/Context';
import { ensureRoute, backRouteWorkflow } from 'utils/routes';
import { getEntityFromString } from 'utils/getEntityFromString';
import { logEvent, trackEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import * as SatismeterUtils from 'utils/satismeter';

import { EVENT_TYPES } from 'constants/Constants';

export function initEntityDetail(entity, id, hasCrudInDetail = false, info) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            Context.entityDetailManager
                .init(entity, id, info)
                .then(({ data, rawData }) => {
                    if (!data) {
                        dispatch({
                            type: ENTITY_DETAIL_INIT_SUCCESS,
                            data: {},
                        });
                        resolve();
                        return;
                    }
                    if (hasCrudInDetail) {
                        dispatch(
                            Context.actions.EntityCrudActions.init({
                                entity,
                                id,
                                extraId: null,
                                isBulkAction: false,
                                isFromDetail: true,
                                data: rawData,
                                isFromTab: Object.entries(getState().entityDetail.tabs).length > 1,
                            }),
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch(console.error);
                    } else {
                        resolve();
                    }
                    dispatch({
                        type: ENTITY_DETAIL_INIT_SUCCESS,
                        data,
                        entity: entity.entity,
                        id,
                    });
                })
                .catch((errors) => {
                    console.error(errors);
                    reject(errors);
                });
        });
}

export function open(
    entity,
    id,
    hasCrudInDetail = false,
    extra,
    forceRefresh = false,
    avoidOpenDrawer = false,
    info = null,
    toTab = false,
    avoidRedirects = false,
) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const entityName = entity.entity;
            const entityDetail = getState().entityDetail;
            const isSameDetail =
                entityDetail?.[entityDetail?.active]?.entity === entityName &&
                entityDetail?.[entityDetail?.active]?.id === id;
            const tabs = !toTab ? {} : entityDetail?.tabs || {};
            const tabId = `${entityName}-${id}`;

            // Avoid duplicated tabs
            if (!tabs[tabId]) tabs[tabId] = { primary: Object.keys(tabs).length === 0 };

            if (isSameDetail && !forceRefresh && !entityDetail.purge) {
                // Executed when you come back from other page
                // Avoiding detail grid rendering problem...
                dispatch({
                    type: ENTITY_DETAIL_LOADING,
                    entity: entityName,
                    loading: true,
                    id,
                    extra,
                });

                !avoidOpenDrawer && dispatch({ type: ENTITY_DETAIL_OPEN, entity, id, extra, tabs });

                setTimeout(() => {
                    dispatch({
                        type: ENTITY_DETAIL_LOADING,
                        entity: entityName,
                        loading: false,
                        id,
                        extra,
                    });
                }, 500);
                resolve();
                return;
            }

            dispatch({
                type: ENTITY_DETAIL_INIT,
                entity: entityName,
                route: entity.route || entityName,
                id,
                hasCrudInDetail,
                extra,
                tabs,
                avoidRedirects,
            });

            dispatch(initEntityDetail(entity, id, hasCrudInDetail, info))
                .then(() => {
                    resolve();
                    logEvent({
                        event: entity.trueName,
                        functionality: 'detailView',
                    });
                    trackEvent({ entity, entityId: id, idEventType: EVENT_TYPES.read });

                    if (toTab) {
                        SatismeterUtils.track('newNavigationSystem');
                    }
                })
                .catch((errors) => {
                    console.error(errors);
                    dispatch({
                        type: ENTITY_DETAIL_INIT_ERROR,
                        errors,
                    });
                    errorToast({ text: getLiteral('helptext_entity_not_accessible') });
                    dispatch(close({ purge: true }));
                });
        });
}

export function hiddenRefresh(entity, id) {
    return (dispatch, getState) => {
        // maybe a new action to put loadingWidgets to true ?
        const entityDetail = getState().entityDetail;
        const tabToRefresh = `${entity.entity}-${id}`;
        const active = entityDetail.active === tabToRefresh ? entityDetail.active : tabToRefresh;
        const hasCrudInDetail = (entityDetail && entityDetail[active]?.hasCrudInDetail) || false;
        dispatch(initEntityDetail(entity, id, hasCrudInDetail));
    };
}

export function initWorkFlow() {
    return (dispatch) => {
        let workFlowPersist = sessionStorage.getItem('fm_workflow_crud_persist');
        if (workFlowPersist) {
            try {
                workFlowPersist = JSON.parse(workFlowPersist);
                let { data, entity, hasCrudInDetail, fieldsToUpdate } = workFlowPersist;
                dispatch(open(entity, data.id, hasCrudInDetail)).then(() => {
                    let workFlowEnd = sessionStorage.getItem('fm_workflow_action_end');
                    const toSave = workFlowEnd === 'true';
                    if (toSave) {
                        dispatch({
                            type: ENTITY_CRUD_INIT_WORKFLOW,
                            data,
                            loading: false,
                            loadingSave: toSave,
                        });

                        dispatch(
                            Context.actions.EntityCrudActions.save({
                                entity,
                                openDetail: false,
                                hasCrudInDetail: true,
                                fieldsToUpdate,
                                fromWorkFlow: true,
                            }),
                        );
                    } else {
                        // remove workflow word if we don't need to do anything more
                        ensureRoute(backRouteWorkflow());
                    }
                    sessionStorage.removeItem('fm_workflow_action_end');
                });
            } catch (ex) {
                console.error(
                    'something went wrong trying to restore previous detail and saving it',
                );
            }
        }
    };
}

export function refresh(entity) {
    return (dispatch, getState) => {
        const detailState = getState().entityDetail;

        if (
            detailState &&
            detailState.isOpen &&
            detailState[detailState.active] &&
            detailState[detailState.active].entity === entity.entity
        ) {
            const hasCrudInDetail = detailState?.[detailState?.active]?.hasCrudInDetail || false;
            dispatch(open(entity, detailState[detailState.active].id, hasCrudInDetail, null, true));
        }
    };
}

export function deleteEntity(entity, activeEntity, isAccumulative) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const state = getState();
            let entityDetail = state.entityDetail;
            entityDetail = entityDetail[entityDetail.active];
            const entityList =
                state?.entityList?.[entityDetail?.entity] ||
                state?.entityList?.[state?.entityList?.active] ||
                null;
            const useLazyLoad = entityList?.useLazyLoad || false;
            let { active, tabs: finalTabs } = state.entityDetail;

            let route, entityToInit, relatedEntity;

            // When deleting an entity while in "Conversations" (the activeEntity)
            // We want to delete the entity but stay in "Conversations"
            if (activeEntity) {
                route = `${activeEntity.route}${entityDetail.route}`;
                entityToInit = activeEntity;
                relatedEntity = entity;
            } else {
                const isMapOpen = window.location.href.includes('/map/');
                route = isMapOpen ? `${entityDetail.route}/map` : entityDetail.route;
                entityToInit = entity;
                relatedEntity = null;
            }

            const tabKey = `${entityDetail.entity}-${entityDetail.id}`;
            const tab = finalTabs[tabKey];

            const shouldUpdateTabs = tab && !tab.primary;

            if (shouldUpdateTabs) {
                let foundIndex = 0;

                finalTabs = Object.entries(finalTabs).reduce((obj, [key, value], index) => {
                    if (key !== tabKey) {
                        obj[key] = value;
                    } else {
                        foundIndex = index;
                    }
                    return obj;
                }, {});

                if (active === tabKey) {
                    if (!!foundIndex) foundIndex--;
                    active = Object.keys(finalTabs)[foundIndex];
                }
            }

            Context.entityDetailManager
                .delete(entity, entityDetail)
                .then((data) => {
                    if (shouldUpdateTabs) {
                        dispatch({
                            type: ENTITY_DETAIL_SET_TABS,
                            active,
                            tabs: finalTabs,
                        });
                        dispatch(
                            Context.actions.EntityActions.clearDataForChip(
                                entity.redux,
                                entityDetail.id,
                            ),
                        );
                        if (useLazyLoad) {
                            dispatch(
                                Context.actions.EntityListActions.init(
                                    entityToInit,
                                    false,
                                    null,
                                    null,
                                    null,
                                    useLazyLoad,
                                    null,
                                    null,
                                    isAccumulative,
                                    relatedEntity,
                                ),
                            );
                        } else {
                            dispatch(Context.actions.EntityListActions.init(entityToInit, true));
                        }
                    } else {
                        dispatch({ type: ENTITY_DETAIL_CLOSE, purge: true });
                        dispatch({ type: ENTITY_CRUD_CLOSE });
                        dispatch(
                            Context.actions.EntityListSelectActions.resetListSelect(entityToInit),
                        );
                        // useLazyLoad for w5 table
                        // make 2 possible calls because null removes default prop values
                        if (useLazyLoad) {
                            dispatch(
                                Context.actions.EntityListActions.init(
                                    entityToInit,
                                    false,
                                    null,
                                    null,
                                    null,
                                    useLazyLoad,
                                    null,
                                    null,
                                    isAccumulative,
                                    relatedEntity,
                                ),
                            );
                        } else {
                            dispatch(Context.actions.EntityListActions.init(entityToInit, true));
                        }
                    }

                    const id = entityDetail?.data?.id || '';
                    if (id) trackEvent({ entity, entityId: id, idEventType: EVENT_TYPES.delete });

                    resolve(data);
                    if (!shouldUpdateTabs && !entityDetail?.avoidRedirects) ensureRoute(route);
                })
                .catch((e) => {
                    console.error(e);
                    let serverError = !e || (e && e.serverError);

                    reject({ errors: e.errors, serverError });
                });
        });
}

export function close(props = {}) {
    return (dispatch, getState) => {
        // Ensuring current route
        const {
            purge = false,
            purgeTabs = false,
            tabs = {},
            isFromTab = false,
            primaryDetail,
        } = props;
        const state = getState();
        let entityDetail = state.entityDetail;
        entityDetail = entityDetail[primaryDetail || entityDetail.active];
        const isMapOpen = window.location.href.includes('/map/');
        const isGridOpen = window.location.href.includes('/grid/');

        // Check if we're going back to the list from a detail tab of
        // a different type and override the return route to the list
        const activeEntityList = getEntityFromString(state.entityList.active);
        const activeEntity =
            activeEntityList?.entity === entityDetail?.entity ? entityDetail : activeEntityList;

        if (entityDetail && activeEntity && !isFromTab && !entityDetail?.avoidRedirects) {
            if (isMapOpen) {
                ensureRoute(`${activeEntity.route || activeEntity.entity}/map`);
            } else if (isGridOpen) {
                ensureRoute(`${activeEntity.route || activeEntity.entity}/grid`);
            } else {
                ensureRoute(`${activeEntity.route || activeEntity.entity}`);
            }
        }
        dispatch({ type: ENTITY_CRUD_CLOSE, tabs });
        dispatch({ type: ENTITY_DETAIL_CLOSE, purge, purgeTabs, tabs });
    };
}

export function openHidden() {
    return (dispatch, getState) => {
        let workFlowPersist = sessionStorage.getItem('fm_workflow_crud_persist');
        if (workFlowPersist) {
            // coming from a workFlowAction, cancelled action, cancelled crud
            workFlowPersist = JSON.parse(workFlowPersist);
            const { data, entity, hasCrudInDetail } = workFlowPersist;
            dispatch(open(entity, data.id, hasCrudInDetail));
        } else {
            dispatch({ type: ENTITY_DETAIL_OPEN });
        }
    };
}

export function setNextDetail(entityType, data) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_DETAIL_SET_NEXT_DETAIL,
            entityType,
            data,
        });
    };
}

export function reset() {
    return (dispatch) => {
        dispatch({ type: ENTITY_DETAIL_RESET });
    };
}

export function resetNextDetail() {
    return (dispatch) => {
        dispatch({ type: ENTITY_DETAIL_RESET_NEXT_DETAIL });
    };
}

export function changeSection(section) {
    return (dispatch) => {
        dispatch({ type: ENTITY_DETAIL_CHANGE_SECTION, section });
    };
}

export function updateDetailFields(entity, id, fields = {}) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_UPDATE_LOCALLY,
            entity: entity.entity,
            id,
            fields,
        });
    };
}

export function updateDetailExtra(entity, id, fields = {}) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_UPDATE_EXTRA,
            entity: entity.entity,
            id,
            fields,
        });
    };
}

export function setActiveTab({ activeKey, deletedKey, tabs }) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_DETAIL_SET_TABS,
            active: activeKey,
            deletedKey,
            tabs,
        });
    };
}

export function updateDetailField(entity, fieldId, newValue) {
    return (dispatch) => {
        return new Promise((resolve) => {
            const dataToUpdate = {
                [fieldId]: newValue.value,
            };
            dispatch(Context.actions.EntityCrudActions.changeFields(dataToUpdate));
            dispatch(
                Context.actions.EntityCrudActions.save({
                    entity,
                    openDetail: true,
                    hasCrudInDetail: true,
                    fieldsToUpdate: dataToUpdate,
                }),
            );
            resolve();
        });
    };
}
