import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import { DashboardContextProvider } from './DashboardContext';

import DashboardHeader from './DashboardHeader';
import DashboardTabs from './DashboardTabs';
import DashboardContainer from './DashboardContainer';

import './styles.scss';

const Dashboard = memo(() => {
    const login = useSelector((state) => state.config.login);
    if (!login) return null;

    return (
        <ContentLayout header={<DashboardHeader />}>
            <DashboardContextProvider>
                <DashboardTabs />
                <DashboardContainer />
            </DashboardContextProvider>
        </ContentLayout>
    );
});

export default Dashboard;
