import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, useTheme } from 'hoi-poi-ui';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { EntityListActions } from 'actions';

import { KPIS } from 'constants/Entities';
import { EntityModalActions } from 'actions';
import { EntityListService } from 'services';

import GoalKpiModel from 'models/GoalKpiModel';
import { REFRESH_TABLE } from 'lib/events';
import { subscribe } from 'lib/EventBuser';
import { getLiteral } from 'utils/getLiteral';
import { getRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import DashboardWidget from '../components/DashboardWidget';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={116} height={100} fill="none">
        <path fill="#FFC5AC" d="m17.289 67.152 1.847.765 4.318-5.75-2.726-1.128-3.44 6.113Z" />
        <path
            fill="#273C50"
            d="m16.293 68.78 5.68 2.353.035-.062a1.835 1.835 0 0 0 .107-1.61c-.214-.527-.664-.96-1.25-1.203l-.658-1.105-2.316-.126-.496-.206-1.102 1.959Z"
        />
        <path fill="#FFC5AC" d="M31.038 69.294h2.052l.975-6.788h-3.027v6.788Z" />
        <path
            fill="#273C50"
            d="M30.966 71.129h6.308v-.069c0-.559-.259-1.094-.72-1.49-.46-.394-1.084-.616-1.735-.616l-1.152-.75-2.15.75h-.55v2.175Z"
        />
        <path
            fill="#37526C"
            d="m33.071 37.807 2.35 13.907.2 3.126-1.232 9.755.5.945-1.398.74h-2.845l-.15-10.304-2.134-8.63-.33 9.781-.456.756c-1.702 3.312-3.993 4.396-5.613 6.872l-2.845-1.323.06-.986c-.572-.248.857-.974.995-1.51l.249-.962 1.66-2.95.063-1.038-.455-7.064.214-9.514 3.732-2.448 7.435.847Z"
        />
        <path
            fill="#FFC5AC"
            d="M29.497 18.094c2.041 0 3.696-1.42 3.696-3.171 0-1.752-1.655-3.171-3.696-3.171-2.041 0-3.696 1.42-3.696 3.17 0 1.752 1.655 3.172 3.696 3.172Z"
        />
        <path
            fill="#FF8C00"
            d="M30.005 40.374c-4.873 0-8.528-1.574-8.578-1.596l-.043-.02.013-.04c.131-.392.255-1.052.03-1.148a.157.157 0 0 1-.061-.038.126.126 0 0 1-.034-.059c-.013-.079.08-.15.207-.25.248-.195.587-.46.388-.888-.35-.752.636-4.608.7-4.855l-.422-6.146c-.076-1.173.32-2.332 1.122-3.284.802-.951 1.962-1.637 3.284-1.94l.01-.003h.01c.136.007.282-.392.392-.684.162-.432.27-.697.452-.686 1.418.079 2.362.13 2.991.163 1.194.064 1.403 1.31 1.428 1.508 1.285.672 2.29 1.674 2.87 2.864.581 1.19.71 2.508.367 3.764l-.595 2.194-.526 1.055.174 2.08c.815.581.581 1.771.535 1.972.776.69.3 1.5.184 1.673.199.208.334.456.394.72.06.265.041.538-.053.795l.716 1.995-.054.016a20.552 20.552 0 0 1-5.901.838Z"
        />
        <path
            fill="#FFC5AC"
            d="M52.903 19.564a1.413 1.413 0 0 0-.48-.342 1.65 1.65 0 0 0-1.217-.04c-.193.07-.367.175-.508.309l-11.687.055-3.32 1.376-.43 1.577 5.364-.192 10.242-1.004c.277.189.626.284.98.266.353-.017.687-.145.938-.36.251-.214.402-.5.423-.803a1.126 1.126 0 0 0-.305-.842Z"
        />
        <path
            fill="#FF8C00"
            d="M39.771 22.855 35.222 24.7l-4.217-1.515-.005-.029c-.079-.535.03-1.078.315-1.56.284-.48.73-.878 1.28-1.14a9.59 9.59 0 0 1 2.835-.841c2.79-.356 3.356-.493 3.361-.494l.061-.016.92 3.749Z"
        />
        <path
            fill="#273C50"
            d="M26.363 16.915a.48.48 0 0 1-.135-.018.85.85 0 0 1-.405-.31c-.655-.808-.972-1.886-.94-3.203.013-.558.087-1.27.541-1.838.383-.478 1.157-.857 1.878-.666a.592.592 0 0 1 .092-.357c.069-.109.172-.2.297-.261.276-.122.581-.188.892-.193.732-.06 1.49-.12 2.23 0 .832.136 1.512.504 1.866 1.01l.007.015.072.3c.01.04.03.078.058.111a.32.32 0 0 0 .107.08.373.373 0 0 0 .277.018.348.348 0 0 1 .295.04c.04.029.072.066.091.107.02.042.026.088.018.132l-.02.12.21-.034a.347.347 0 0 1 .156.01c.05.016.094.042.128.077a.244.244 0 0 1 .067.122.223.223 0 0 1-.014.134l-.06.137c.044 0 .087.008.126.023a.32.32 0 0 1 .105.064c.042.04.069.089.076.142a.22.22 0 0 1-.036.153c-.234.342-.566.629-.964.831a2.512 2.512 0 0 1-.704.238 15.726 15.726 0 0 1-4.907.127c.096.276.134.564.11.852a.821.821 0 0 1-.19.453c-.111.133-.262.238-.437.302-.075.021-.151.037-.229.048-.074.01-.147.025-.219.045a.647.647 0 0 0-.27.164.518.518 0 0 0-.14.259.476.476 0 0 0 .031.283c.04.09.106.171.192.233l.162-.145-.053.184a.252.252 0 0 1-.057.098.302.302 0 0 1-.1.07.488.488 0 0 1-.204.043Z"
        />
        <path
            fill="#DFE2E5"
            d="M51.113 55.566c11.657 0 21.108-7.985 21.108-17.834 0-9.85-9.45-17.834-21.108-17.834-11.658 0-21.108 7.984-21.108 17.834s9.45 17.834 21.108 17.834Z"
        />
        <path
            fill="#FFF1CC"
            d="M68.602 37.735c.003 2.138-.544 4.251-1.603 6.187-.032.06-.066.12-.1.18-1.618 2.844-4.248 5.174-7.509 6.654l-.205.091c-2.498 1.099-5.266 1.67-8.072 1.665-.974 0-1.946-.068-2.907-.203l-.228-.034c-3.595-.549-6.9-2.047-9.456-4.285l-.16-.142c-2.448-2.192-4.04-4.982-4.559-7.985-.01-.065-.022-.131-.031-.197-.473-3.053.19-6.155 1.894-8.866.036-.059.074-.117.111-.175 1.313-2.011 3.15-3.734 5.368-5.033l.191-.112c2.143-1.223 4.573-2.026 7.119-2.353a20.788 20.788 0 0 1 2.658-.17 20.138 20.138 0 0 1 7.62 1.473c2.173.89 4.108 2.155 5.686 3.716.05.05.102.1.15.151 1.907 1.93 3.196 4.253 3.742 6.74v.001a12.588 12.588 0 0 1 .291 2.697Z"
        />
        <path
            fill="#FF8C00"
            d="M52.048 36.68c.273.263.451.578.515.912l9.541-1.423c-.38-1.58-1.282-3.053-2.617-4.277l-7.439 4.789Z"
        />
        <path
            fill="#FF8C00"
            d="M61.984 36.256a3.538 3.538 0 0 0-.039-.195v-.001c-.34-1.57-1.149-3.038-2.35-4.258a11.041 11.041 0 0 0-3.615-2.42c-.07-.028-.138-.057-.21-.085a12.83 12.83 0 0 0-6.417-.754 12.168 12.168 0 0 0-4.477 1.521c-1.424.828-2.6 1.932-3.435 3.222l-.112.176c-1.042 1.703-1.434 3.645-1.12 5.55.01.066.02.132.035.196.35 1.835 1.341 3.536 2.84 4.878.052.048.106.096.161.142 1.578 1.365 3.603 2.286 5.805 2.64l.23.034c2.346.334 4.756.017 6.878-.905.069-.03.136-.06.204-.093h.001c2.007-.92 3.63-2.354 4.645-4.1.035-.06.069-.12.101-.18.916-1.672 1.221-3.544.875-5.368Zm-1.086 5.286c-.032.06-.066.12-.1.179-.996 1.707-2.584 3.107-4.545 4.008l-.205.092c-2.076.903-4.434 1.212-6.729.884a5.74 5.74 0 0 1-.23-.033c-2.152-.347-4.13-1.246-5.673-2.579-.056-.047-.11-.094-.163-.142-1.464-1.313-2.433-2.974-2.78-4.767a5.21 5.21 0 0 1-.034-.196c-.308-1.863.075-3.762 1.094-5.429.035-.059.072-.117.11-.176.82-1.264 1.973-2.346 3.369-3.157a11.921 11.921 0 0 1 4.603-1.516 12.55 12.55 0 0 1 6.265.853 10.798 10.798 0 0 1 3.536 2.366c1.176 1.194 1.97 2.63 2.302 4.168.014.064.027.129.038.196.337 1.784.038 3.613-.858 5.249Z"
        />
        <path
            fill="#FF8C00"
            d="m50.307 36.25-.189.115-5.106-6.132-.136-.162-3.731-4.48.191-.111 3.734 4.48.134.164 5.103 6.125ZM49.394 37.037l-.112.175-7.748-3.652-.205-.097-5.663-2.67.112-.175 5.663 2.669.203.096 7.75 3.654ZM49.296 38.296l-8.822.888-.23.024-6.44.648-.032-.197 6.437-.648.23-.023 8.83-.889.027.197ZM49.865 38.963l-6.45 5.128-.17.135-4.722 3.755-.161-.142 4.722-3.755.17-.135 6.454-5.132.157.146ZM58.733 24.424l-2.752 4.96h-.001l-.1.18-3.762 6.78-.21-.085 3.763-6.78.1-.18 2.752-4.96.21.085ZM64.57 28.29 59.595 31.8l-.18.128-6.8 4.8-.147-.153 6.797-4.799.178-.126 4.976-3.513c.05.05.102.1.15.152ZM68.352 35.224l-6.369 1.03-.227.037-8.714 1.41-.043-.195 8.719-1.411.227-.036v-.001l6.366-1.03c.015.066.028.13.04.196ZM67 43.917l-.1.179-5.892-2.291-.21-.083-7.958-3.093.096-.181 7.962 3.095.211.083 5.89 2.29ZM59.39 50.753c-.068.03-.137.062-.205.091l-3.027-4.843-.11-.176-4.137-6.62.204-.093 4.138 6.622.11.175 3.027 4.844ZM50.777 39.368l-1.458 7.344-.04.197-1.072 5.4-.229-.035 1.072-5.4.04-.196 1.457-7.344.23.034ZM50.843 36.133l-.23.027-1.228-7.417-.032-.197-.898-5.423c.077-.01.153-.02.23-.028l.897 5.422.033.197 1.228 7.419Z"
        />
        <path
            fill="#273C50"
            d="M51.421 39.885c1.211 0 2.193-1.03 2.193-2.299 0-1.27-.982-2.299-2.193-2.299s-2.193 1.03-2.193 2.3c0 1.269.982 2.298 2.193 2.298Z"
        />
        <path fill="#FFC5AC" d="m96.442 83.338-1.65 1.185-6.111-4.895 2.434-1.749 5.327 5.46Z" />
        <path
            fill="#273C50"
            d="M97.941 84.778 92.87 88.42l-.053-.055c-.439-.449-.651-1.029-.591-1.613.06-.583.388-1.123.912-1.499l.338-1.269 2.317-.638.442-.318 1.707 1.749Z"
        />
        <path fill="#FFC5AC" d="M81.706 87.858h-2.151l-1.024-7.12h3.176l-.001 7.12Z" />
        <path
            fill="#273C50"
            d="M81.781 89.79h-6.615v-.073c0-.585.272-1.147.755-1.562.482-.414 1.137-.647 1.82-.647l1.209-.786 2.254.786h.578v2.282Z"
        />
        <path
            fill="#37526C"
            d="M87.087 53.795c.52.557.26 2.452.26 2.452l.127 11.802c2.208 1.56 3.9 8.04 3.9 8.04l2.109 3.328c-.26.67-1.946 3.284-2.466 3.173-.24-.052-3.242-2.427-5.238-5.949-2.306-4.068-3.63-9.359-3.63-9.359s.26 15.16.26 15.829c0 .669-3.507.334-4.287.334-.78 0-4.791-27.31-4.791-27.31v-1.56l.65-.78s12.586-.558 13.106 0Z"
        />
        <path
            fill="#273C50"
            d="m57.364 34.248-1.996 1.04 2.224-.52s1.634.074 1.634-.668l2.66-1.633s2.261 1.336 2.926.594c.665-.743-.532-2.227-.532-2.227s.802-.493.733-.692c-.068-.199-1.132.098-1.132.098s-2.527-1.633-2.394 1.188l-2.66 1.968s-1.33-.038-1.463.852Z"
        />
        <path
            fill="#98D5F1"
            d="M87.808 57.65c-2.757 1.173-6.037 1.528-9.128 1.217-2.664-.27-3.218-2.823-5.69-3.73.781-1.926.79-4.483.659-5.2-.226-1.226-.268-9.766.56-10.656 1.084-1.167 2.741-1.79 2.666-3.281l.232.204c-.293-.662.275-1.42 1.022-1.72.748-.299 1.612-.26 2.436-.217l2.08.112c-.09.243-.112.5-.063.753.049.251.167.49.344.694 1.23-.29 2.526.483 3.076 1.47.55.987.536 2.127.511 3.22-.028 1.266 1.065 10.155-.258 10.717.682.148 1.647 3.27 1.065 2.93.269.163.204 1.674.488 3.487Z"
        />
        <path
            fill="#FFC5AC"
            d="M59.47 32.38c.253-.18.54-.311.84-.387.3-.075.607-.091.898-.048.29.043.559.145.785.298.227.154.407.355.527.59l13.909 3.96-1.575 1.904-13.548-3.21a2.349 2.349 0 0 1-1.482.146c-.474-.116-.86-.391-1.087-.774a1.628 1.628 0 0 1-.141-1.304c.135-.456.446-.874.874-1.175Z"
        />
        <path
            fill="#98D5F1"
            d="M73.783 34.784c-.235.106-7.164-1.214-7.25-.992l.253 1.92-.622 1.598 2.998 1.168.809.201.55.214c-.086.224 1.199.738 1.324.922.061.09.145.164.245.218a.83.83 0 0 0 .338.094l4.294.267c.602.12 1.265.01 1.842-.303.578-.313 1.023-.806 1.239-1.37.215-.564.183-1.153-.09-1.638-.272-.485-.763-.826-1.364-.949l-.712-.258-1.09-.396-2.04-.74a.882.882 0 0 0-.357-.05c-.125.007-.25.04-.367.094Z"
        />
        <path
            fill="#FFC5AC"
            d="M80.128 33.272c2.355 0 4.264-1.638 4.264-3.66 0-2.02-1.909-3.658-4.264-3.658-2.355 0-4.264 1.638-4.264 3.659 0 2.02 1.909 3.659 4.264 3.659Z"
        />
        <path
            fill="#273C50"
            d="M85.215 28.453c-.237-.808-1.122-1.512-2.088-1.43.12-.622-.363-1.249-1.013-1.542-.65-.293-1.428-.306-2.153-.204-.42.066-.838.15-1.249.25-.349.081-1.985-.4-2.332-.311-.296.075-1.01.986-1.234 1.168-.225.181-.344.487-.194.719.148.228.488.303.792.324.304.022.63.018.883.165.325.19.416.566.403.902-.013.337-.103.676-.03 1.006.074.33.385.661.777.638.293-.265.828-.248 1.164-.023.16.12.289.269.376.436.087.167.132.348.13.531a3.424 3.424 0 0 1-.197 1.08c-.07.24-.124.534.085.707.123.084.27.137.427.153.615.112 2.926-.04 3.541.072l-.047-.08c.446-.74 1.038-1.41 1.476-2.153.438-.742.72-1.601.483-2.408Z"
        />
        <path
            fill="#FFC5AC"
            d="M91.76 58.663a2.28 2.28 0 0 1-.826-.345 1.905 1.905 0 0 1-.578-.612 1.614 1.614 0 0 1-.221-.765c-.008-.266.052-.53.176-.774l-5.027-12.65 2.53-.142 5.436 11.89c.463.205.822.55 1.009.967.187.418.189.88.005 1.3-.183.418-.54.765-1.001.974a2.449 2.449 0 0 1-1.503.157Z"
        />
        <path
            fill="#98D5F1"
            d="M84.12 45.173c.229.098 3.277 5.963 3.513 5.877l1.45-1.467 1.704-.622-.82-2.938-.318-.716-.151-.538c.238-.089-.102-1.356-.024-1.57a.66.66 0 0 0-.09-.627l-2.347-3.266c-.261-.514-.749-.917-1.356-1.123a2.844 2.844 0 0 0-1.884.027c-.599.222-1.07.64-1.311 1.16a1.84 1.84 0 0 0 .026 1.616l.21.684.323 1.05.604 1.962a.725.725 0 0 0 .172.29.877.877 0 0 0 .299.2Z"
        />
        <path
            fill="#FDF6CB"
            d="m103.573 87.688-1.188-1.638-.237.936c-.042.164-.083.33-.122.496a17.092 17.092 0 0 0-1.047-.695c-1.079-.672-2.157-1.344-3.234-2.018l.337 1.68c.208 1.033.425 2.088.94 3.033.057.107.118.213.185.316h5.013c.041-.096.068-.195.083-.296.002-.006.002-.013.002-.02.079-.64-.334-1.245-.732-1.794Z"
        />
        <path
            fill="#A9B1B9"
            d="M107.816 89.96 65.331 90a.2.2 0 0 1-.13-.046.147.147 0 0 1-.053-.111c0-.042.019-.082.053-.112a.2.2 0 0 1 .13-.046l42.485-.04a.208.208 0 0 1 .13.046.156.156 0 0 1 .04.05c.009.02.014.04.014.06 0 .022-.005.042-.014.061a.156.156 0 0 1-.04.051.206.206 0 0 1-.13.046Z"
        />
        <path
            fill="#FDF6CB"
            d="m46.425 68.949-1.188-1.638-.238.936c-.041.164-.083.33-.121.495a17.118 17.118 0 0 0-1.048-.695c-1.078-.671-2.156-1.344-3.234-2.017l.338 1.679c.208 1.033.425 2.088.94 3.034.056.107.118.212.184.316h5.014a1.229 1.229 0 0 0 .084-.316c.08-.642-.334-1.246-.731-1.794Z"
        />
        <path
            fill="#A9B1B9"
            d="m50.668 71.22-42.485.04a.2.2 0 0 1-.13-.046.147.147 0 0 1-.053-.111c0-.042.02-.082.054-.111a.2.2 0 0 1 .129-.047l42.485-.04c.024 0 .048.004.07.012a.19.19 0 0 1 .06.034.16.16 0 0 1 .04.05.139.139 0 0 1 0 .121.16.16 0 0 1-.04.052.21.21 0 0 1-.13.046Z"
        />
        <path
            fill="#FFC5AC"
            d="M31.018 40.825a1.506 1.506 0 0 1-.537-.272 1.25 1.25 0 0 1-.353-.44 1.08 1.08 0 0 1 .066-1.044l-3.885-9.457.389-3.147 1.585-.88 1.6 4.398 2.356 8.61c.3.16.522.41.623.702.1.291.072.604-.078.88-.15.274-.413.492-.739.613-.325.12-.69.133-1.027.037Z"
        />
        <path fill="#FF8C00" d="m24.215 23.154 3.675-1.22 2.133 6.102-4.924 1.34-.884-6.222Z" />
    </svg>
);

const GoalsDashboard = memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState();

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);

        EntityListService.get(KPIS, {
            userId: idUser,
            total: 3,
        })
            .then((data) => {
                const goals = data.slice(0, 3);
                setList(goals?.length ? GoalKpiModel.toList({ data: goals }) : goals);
            })
            .catch((error) => {
                console.error(error);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(KPIS, true));
        return subscribe(`${REFRESH_TABLE}_${KPIS.entity}`, () => {
            getList();
        });
    }, [dispatch, getList]);

    useEffect(() => {
        getList();
    }, [getList]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: KPIS.trueName,
            functionality: 'list',
        });
        window.open(getRoute(KPIS.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        dispatch(
            EntityModalActions.init({
                entity: KPIS,
                data: {},
                labels: {
                    title: getLiteral('title_kpi_new_goal'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('error_insert_goal'),
                },
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: KPIS.trueName,
            functionality: 'create',
        });
    }, [dispatch]);

    const emptyActions = useMemo(() => {
        return [
            <Button type="secondary" size="small" onClick={onAdd}>
                {getLiteral('action_add_goal')}
            </Button>,
        ];
    }, [onAdd]);

    const goal = useMemo(() => {
        if (!list) return null;
        return list.map((goal) => {
            const percentage = goal.progressPercentage;

            let pathColor = theme.colors.red500;
            if (percentage >= 50 && percentage < 100) pathColor = theme.colors.yellow500;
            else if (percentage >= 100) pathColor = theme.colors.green500;

            const styles = buildStyles({
                rotation: -0.25,
                strokeLinecap: 'round',
                pathTransition: 'none',
                pathColor: pathColor,
                trailColor: theme.colors.neutral200,
            });

            return (
                <div className="fm-goals-dashboard-widget__goal">
                    <Text type="subtitle" className="fm-goals-dashboard-widget__goal__name">
                        {goal.name}
                    </Text>
                    <CircularProgressbarWithChildren styles={styles} value={percentage}>
                        <Text type="h6" color="neutral900">
                            {percentage}%
                        </Text>
                        <div className="fm-goals-dashboard-widget__goal__divider"></div>
                        <Text type="subtitle1" color="neutral700">
                            {goal.progressValueText}/{goal.goal}
                        </Text>
                    </CircularProgressbarWithChildren>
                    <div className="fm-goals-dashboard-widget__goal__freq">
                        <Text color="neutral900" type="caption">
                            {goal.frequency}
                        </Text>
                        <Text color="neutral700" type="caption">
                            {goal.assignmentLabel}
                        </Text>
                    </div>
                </div>
            );
        });
    }, [
        list,
        theme.colors.green500,
        theme.colors.neutral200,
        theme.colors.red500,
        theme.colors.yellow500,
    ]);

    const showList = !error && !loading;

    return (
        <DashboardWidget
            title={getLiteral('label_goals')}
            // Actions
            onViewMore={onViewMore}
            onAdd={onAdd}
            addTooltip={getLiteral('action_add_goal')}
            // State
            isLoading={loading}
            isEmpty={list?.length === 0 && !error}
            isError={error}
            // Empty view
            emptyImage={EMPTY_SVG}
            emptyTitle={getLiteral('label_empty_widget_goals')}
            emptySubtitle={getLiteral('label_empty_widget_goals_desc')}
            emptyActions={emptyActions}
        >
            {showList && (
                <div className="fm-goals-dashboard-widget__container">
                    <div className="fm-goals-dashboard-widget__goals">{goal}</div>
                </div>
            )}
        </DashboardWidget>
    );
});

export default GoalsDashboard;
