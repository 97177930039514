import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityFiltersActions } from 'actions';
import { CROSS_FILTERS } from 'constants/Features';
import { getLiteral } from 'utils/getLiteral';
import { getCrossID, getRealID, getMappedFilters } from 'utils/filters';
import { getBackendBoolean } from 'utils/fm';
import CompaniesConfig from 'containers/companies/EntityConfig';
import ContactsConfig from 'containers/contacts/TableConfig';
import ActivitiesConfig from 'containers/Activities/EntityConfig';
import OpportunitiesConfig from 'containers/opportunities/EntityConfig';
import SalesOrdersConfig from 'containers/SalesOrders/TableConfig';
import Tours, { CROSS_FILTERS_TOUR } from '../components/Tours';

import { COMPANIES, CONTACTS, ACTIVITIES, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import {
    ENTITIES_ALLOWED_FOR_CROSS_FILTERS_PER_ENTITY,
    ENTITIES_WITH_CROSS_FILTERS,
    SENT_TO_BE_AS_STANDARD_FILTERS,
} from '../constants';

const useCrossFilters = ({ entity }) => {
    const {
        cache,
        filters,
        operators,
        crossFilters,
        crossOperators,
        permissions,
        USER_HAS_CROSSFILTERS,
        showTheTour,
        forceCrossFilters,
        hasUserFlow,
    } = useSelector((state) => {
        const entityName = entity?.entity;
        const cache = state.config.components[`${entityName}_filters`] || {};
        const {
            filters = {},
            operators = {},
            crossFilters = {},
            crossOperators = {},
            forceCrossFilters = true,
        } = state.entityFilters?.[entityName] || {};
        const permission = state.config?.permission || {};
        return {
            cache,
            filters,
            operators,
            crossFilters,
            crossOperators,
            forceCrossFilters,
            permissions: {
                [ACTIVITIES.entity]: permission.gestiones,
                [COMPANIES.entity]: permission.empresas,
                [CONTACTS.entity]: permission.contactos,
                [OPPORTUNITIES.entity]: permission.expedientes,
                [SALESORDERS.entity]: permission.salesOrders,
            },
            USER_HAS_CROSSFILTERS:
                process.env.NODE_ENV === 'development' ||
                state.config.flagsmith?.[CROSS_FILTERS] ||
                false,
            showTheTour: !state.config.components?.tours?.filters?.[CROSS_FILTERS_TOUR],
            hasUserFlow: getBackendBoolean(state.config.userData?.userFlow),
        };
    });

    const dispatch = useDispatch();

    const crossLabels = useMemo(
        () => ({
            [COMPANIES.entity]: getLiteral('title_accounts'),
            [CONTACTS.entity]: getLiteral('title_contacts'),
            [ACTIVITIES.entity]: getLiteral('title_activities'),
            [OPPORTUNITIES.entity]: getLiteral('title_opportunities'),
            [SALESORDERS.entity]: getLiteral('title_salesorders'),
        }),
        [],
    );

    const ENTITIES_ALLOWED = useMemo(
        () => (entity?.entity ? ENTITIES_ALLOWED_FOR_CROSS_FILTERS_PER_ENTITY[entity.entity] : []),
        [entity],
    );

    const ENTITIES_ALLOWED_PER_NAME = useMemo(
        () =>
            entity && ENTITIES_ALLOWED?.length
                ? ENTITIES_ALLOWED.map((entity) => entity?.entity)
                : [],
        [ENTITIES_ALLOWED, entity],
    );

    const CrossEntitiesConfig = useMemo(
        () => ({
            [COMPANIES.entity]: {
                title: getLiteral('label_filters_cross_filters_section_account'),
                icon: 'accounts',
                config: CompaniesConfig(),
                allowed: permissions[COMPANIES.entity],
            },
            [CONTACTS.entity]: {
                title: getLiteral('label_filters_cross_filters_section_contact'),
                icon: 'contacts',
                config: ContactsConfig(),
                allowed: permissions[CONTACTS.entity],
            },
            [ACTIVITIES.entity]: {
                title: getLiteral('label_filters_cross_filters_section_activity'),
                icon: 'activities',
                config: ActivitiesConfig(),
                allowed: permissions[ACTIVITIES.entity],
            },
            [OPPORTUNITIES.entity]: {
                title: getLiteral('label_filters_cross_filters_section_opportunity'),
                icon: 'opportunities',
                config: OpportunitiesConfig(),
                allowed: permissions[OPPORTUNITIES.entity],
            },
            [SALESORDERS.entity]: {
                title: getLiteral('label_filters_cross_filters_section_salesorders'),
                icon: 'salesOrders',
                config: SalesOrdersConfig(),
                allowed: permissions[SALESORDERS.entity],
            },
        }),
        [permissions],
    );

    const hasCrossFilters = useMemo(() => {
        return (
            USER_HAS_CROSSFILTERS &&
            entity &&
            ENTITIES_WITH_CROSS_FILTERS.includes(entity?.trueName)
        );
    }, [USER_HAS_CROSSFILTERS, entity]);

    const crossFiltersTour = useMemo(() => {
        return !hasUserFlow && hasCrossFilters && showTheTour ? <Tours entity={entity} /> : null;
    }, [entity, hasCrossFilters, showTheTour, hasUserFlow]);

    const initCrossFilters = useCallback(() => {
        if (!entity) return null;

        const mappedPromises = ENTITIES_ALLOWED.reduce((obj, item) => {
            if (item.entity === entity.entity || !CrossEntitiesConfig[item.entity]?.allowed)
                return obj;
            obj[item.entity] = dispatch(EntityFiltersActions.initFilters(item));
            return obj;
        }, {});

        const entities = Object.keys(mappedPromises);
        const promises = Object.values(mappedPromises);

        return Promise.all(promises).then((results) => {
            const obj = {};
            for (let i = 0; i < entities.length; i++) {
                const [schema, extraFieldsTab] = results[i];
                obj[entities[i]] = { schema, extraFieldsTab };
            }

            return obj;
        });
    }, [CrossEntitiesConfig, ENTITIES_ALLOWED, dispatch, entity]);

    const getDefaultFilters = useCallback(({ crossSchema, defaultFilters, filtersToIgnore }) => {
        const newDefaultFilters = Object.entries(crossSchema).reduce(
            (arr, [entity, schema]) => {
                const filteredFilters = (schema.defaultFilters || []).filter(
                    (filter) => !filtersToIgnore.includes(filter),
                );
                const crossIDFilters = filteredFilters.map((filter) => getCrossID(filter, entity));
                return arr.concat(crossIDFilters);
            },
            [...defaultFilters],
        );
        return newDefaultFilters;
    }, []);

    const getCrossFilters = useCallback(
        ({ crossFilters, getMappedSchema, filtersToIgnore = [], visibleFilters = [] }) => {
            if (!entity) return;

            const entityName = entity.entity;
            const mappedVisibleFilters = getMappedFilters(visibleFilters, entityName);
            let finalVisibleFilters = [...visibleFilters];

            const shoulAddDefaultCrossFilters =
                forceCrossFilters ||
                Object.keys(mappedVisibleFilters).filter((key) => key !== entityName).length === 0;

            const getDefaultCrossFilters = (filters = [], crossEntity) =>
                filters.map((filter) => getCrossID(filter, crossEntity));

            const crossSchema = Object.entries(CrossEntitiesConfig).reduce(
                (obj, [key, { title, config, allowed }]) => {
                    if (key === entityName || !ENTITIES_ALLOWED_PER_NAME.includes(key) || !allowed)
                        return obj;

                    const { schema, extraFieldsTab } = crossFilters[key];

                    const {
                        defaultCrossFilters = {},
                        filtersAddButtonSchema,
                        hiddenFilters = [],
                    } = config;

                    const defaultFilters = defaultCrossFilters[entityName] || [];

                    const { schemaByFieldId: mappedSchema } = getMappedSchema(schema);

                    let cachedVisible = mappedVisibleFilters?.[key] || [];
                    let visibleFilters = cachedVisible ? [...cachedVisible] : [...defaultFilters];

                    visibleFilters = visibleFilters.filter(
                        (current) => !filtersToIgnore.includes(current),
                    );

                    const newDefaultFilters = defaultFilters.filter(
                        (current) => !filtersToIgnore.includes(current),
                    );

                    // Adding extra field groups
                    const groupedExtraFields = extraFieldsTab.reduce((groups, tab) => {
                        groups.push({
                            label: tab.title,
                            id: tab.title,
                            options: tab.fields.map((field) => field.id),
                        });
                        return groups;
                    }, []);

                    // Assume that if the user has not applied any cross-filters and has not completed the tour,
                    // they have never used cross-filters. Therefore, set the default state of cross-filters
                    // for each entity to visible to promote the feature.
                    if (shoulAddDefaultCrossFilters) {
                        finalVisibleFilters = [
                            ...finalVisibleFilters,
                            ...getDefaultCrossFilters(defaultFilters, key),
                        ];
                    }

                    finalVisibleFilters = finalVisibleFilters.filter(
                        (current) => !filtersToIgnore.includes(getRealID(current).id),
                    );

                    obj[key] = {
                        schema,
                        mappedSchema,
                        visibleFilters,
                        hiddenFilters,
                        extraSchema: [...(filtersAddButtonSchema || []), ...groupedExtraFields],
                        title,
                        useSections: true,
                        defaultFilters: newDefaultFilters,
                    };
                    return obj;
                },
                {},
            );

            // Remove possible duplicates from finalVisibleFilters
            finalVisibleFilters = [...new Set(finalVisibleFilters)];

            return { crossSchema, visibleFilters: finalVisibleFilters };
        },
        [CrossEntitiesConfig, ENTITIES_ALLOWED_PER_NAME, entity, forceCrossFilters],
    );

    const getFinalCrossSchema = useCallback(
        ({ crossSchema, getFieldsFormatted, getSubfilterSchema, quickfilters, visibleFilters }) => {
            const finalCrossSchema = Object.entries(crossSchema).reduce(
                (finalCrossSchema, [key, obj]) => {
                    const { hiddenFilters, mappedSchema, title, useSections, extraSchema } = obj;

                    const filters = visibleFilters?.[key];

                    const schema = filters
                        ? filters
                              .filter((filter) => !quickfilters?.[key]?.includes(filter))
                              .reduce((arr, current) => {
                                  if (!mappedSchema[current] || hiddenFilters.includes(current))
                                      return arr;

                                  let isRemovable = true;
                                  let isPinnable = true;
                                  let isCrossFilter = false;
                                  let subfilterSchema;

                                  if (mappedSchema[current]?.hasOwnProperty('isRemovable')) {
                                      isRemovable = mappedSchema[current].isRemovable;
                                  }

                                  if (mappedSchema[current]?.hasOwnProperty('isPinnable')) {
                                      isPinnable = mappedSchema[current].isPinnable;
                                  }

                                  if (mappedSchema[current]?.hasOwnProperty('isCrossFilter')) {
                                      isCrossFilter = mappedSchema[current].isCrossFilter;
                                  }

                                  if (mappedSchema[current]?.hasOwnProperty('subfilterKey')) {
                                      subfilterSchema = getSubfilterSchema({
                                          crossEntity: key,
                                          filter: current,
                                          key: mappedSchema[current].subfilterKey,
                                          schema: mappedSchema,
                                      });
                                  }

                                  arr.push({
                                      label: mappedSchema[current].description,
                                      id: mappedSchema[current].id,
                                      fields: [...getFieldsFormatted(mappedSchema[current])],
                                      isRemovable,
                                      isPinnable,
                                      isCrossFilter,
                                      entity: key,
                                      crossId: getCrossID(mappedSchema[current].id, key),
                                      subfilterSchema,
                                  });

                                  return arr;
                              }, [])
                        : [];

                    if (schema.length === 0) return finalCrossSchema;

                    finalCrossSchema[key] = { title, useSections, schema, extraSchema };

                    return finalCrossSchema;
                },
                {},
            );

            return finalCrossSchema;
        },
        [],
    );

    const getHasCrossFiltersActive = useCallback((crossFilters) => {
        if (!crossFilters || Object.entries(crossFilters).length === 0) return false;
        return Object.keys(crossFilters).every((entity) => {
            return !(
                typeof crossFilters[entity] === 'object' &&
                crossFilters[entity] !== null &&
                Object.keys(crossFilters[entity]).length === 0
            );
        });
    }, []);

    const getCrossValues = useCallback(
        ({ crossFilters, crossSchema, getValues }) => {
            if (!crossSchema) return {};
            const crossValues = Object.entries(crossSchema).reduce((crossValues, [key, obj]) => {
                const { mappedSchema } = obj;
                const filters = crossFilters?.[key];
                const crossEntity = CrossEntitiesConfig[key].config.entity;
                if (!crossEntity || !filters || Object.entries(filters).length === 0)
                    return crossValues;

                return {
                    ...crossValues,
                    ...getValues({ filters, crossEntity, mappedSchema }),
                };
            }, {});

            return crossValues;
        },
        [CrossEntitiesConfig],
    );

    const getModalOptionsSchema = useCallback(
        ({ crossSchema, extraSchema, getExtraFiltersSchema, hiddenFilters }) => {
            const crossEntitiesSchema = [];
            const extraMultiSchema = [];
            const selectorsSchema = [];

            if (crossSchema && extraSchema) {
                extraMultiSchema.push({
                    id: entity.entity,
                    label: CrossEntitiesConfig[entity.entity].title,
                    schema: extraSchema,
                });

                Object.entries(crossSchema).forEach(([id, obj]) => {
                    if (id !== entity.entity) {
                        const { title: label, icon: iconType, config } = CrossEntitiesConfig[id];
                        const { schema, extraSchema } = obj;
                        crossEntitiesSchema.push({ id, label, iconType });
                        extraMultiSchema.push({
                            id,
                            label,
                            schema: getExtraFiltersSchema({
                                schema,
                                extraSchema,
                                entity: config.entity,
                                hiddenFilters,
                            }),
                        });
                    }
                });

                selectorsSchema.push({
                    label: getLiteral('label_related_entites'),
                    schema: crossEntitiesSchema,
                    selectAllLabel: getLiteral('label_selectall'),
                });
            }

            return {
                extraMultiSchema,
                selectorsSchema,
            };
        },
        [CrossEntitiesConfig, entity?.entity],
    );

    const totalActiveFilters = useMemo(() => {
        if (!entity) return 0;

        const entityName = entity.entity;
        const mappedVisibleFilters = getMappedFilters(cache.visibleFilters || [], entityName);

        const countActiveFilters = ({ visibleFilters, filters, operators }) => {
            let activeFilterCount = 0;

            if (Object.entries(filters).length) {
                const activeFilters = Object.keys(filters).filter((key) => {
                    return (
                        !filters[key].hideForCount &&
                        ([...visibleFilters, ...SENT_TO_BE_AS_STANDARD_FILTERS].includes(key) ||
                            !visibleFilters)
                    );
                });

                // Exclude 'search' filter from count if present
                activeFilterCount = activeFilters.length - (filters.search ? 1 : 0);
            }

            // Count operator keys that don't have corresponding filters
            if (Object.entries(operators).length) {
                activeFilterCount += Object.keys(operators).filter((key) => !filters[key]).length;
            }

            return activeFilterCount;
        };

        return [entityName, ...ENTITIES_ALLOWED_PER_NAME].reduce((total, item) => {
            const isMainEntity = entityName === item;
            const itemFilters = isMainEntity ? filters : crossFilters[item];
            const itemOperators = isMainEntity ? operators : crossOperators[item];

            return (
                total +
                countActiveFilters({
                    visibleFilters: mappedVisibleFilters?.[item] || [],
                    filters: itemFilters || {},
                    operators: itemOperators || {},
                })
            );
        }, 0);
    }, [
        ENTITIES_ALLOWED_PER_NAME,
        cache.visibleFilters,
        crossFilters,
        crossOperators,
        entity,
        filters,
        operators,
    ]);

    return {
        crossFiltersTour,
        crossLabels,
        getCrossFilters,
        getCrossValues,
        getDefaultFilters,
        getFinalCrossSchema,
        getHasCrossFiltersActive,
        getModalOptionsSchema,
        hasCrossFilters,
        initCrossFilters,
        totalActiveFilters,
    };
};

export default useCrossFilters;
