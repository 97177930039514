import React, { memo, useContext, useEffect } from 'react';
import { getDashboards } from 'services/Dashboards';
import { errorToast } from 'utils/toast';
import { DashboardContext } from './DashboardContext';

const DashboardTabs = memo(() => {
    const { dashboards, setDashboards, setActiveDashboard } = useContext(DashboardContext);

    useEffect(() => {
        getDashboards()
            .then((result) => {
                if (!result?.length) return;
                setDashboards(result);
                // TODO cache active dashboard (id)
                setActiveDashboard(result[0]);
            })
            .catch((error) => {
                console.error(error);
                errorToast({ text: getLiteral('error_tryitagain') });
            });
    }, [setActiveDashboard, setDashboards]);

    if (!dashboards?.length) return;

    return <div></div>;
});

export default DashboardTabs;
