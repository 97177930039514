import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions } from 'actions';
import { Button, Breadcrumbs } from 'hoi-poi-ui';
import { FilterViews } from '@web/web5';

import useCrossFilters from 'containers/components/NewEntityFilters/hooks/useCrossFilters';
import MenuHoi from 'components/MenuHoi';
import { ACTIVITIES, AGENDA, COMPANIES, CONTACTS, OPPORTUNITIES } from 'constants/Entities';
import MenuHelp from 'containers/MenuHelp';

import { intercomHideAndShow } from 'utils/intercom';
import { userflowHideAndShow } from 'utils/userflow';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';

import EntityMenuBulkActions from './BulkActions';
import MenuFilters from './MenuFilters';
import EnableSync from './EnableSync';

import './style.scss';

const propTypes = {
    create: PropTypes.bool,
    onAdd: PropTypes.func,
    withFilters: PropTypes.bool,
    canCreate: PropTypes.bool,
    withBreadcrumb: PropTypes.bool,
    //Breadcrumb
    onClickBreadcrumb: PropTypes.func,
    folderSelectedArr: PropTypes.array,
    //SectionTitle
    withSectionTitle: PropTypes.bool,
    sectionTitle: PropTypes.string,
    //BulkOperations
    withBulkOperations: PropTypes.bool,
    bulkSelectedEntities: PropTypes.number,
    onFinishBulk: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleFilters: bindActionCreators(EntityFiltersActions, dispatch).toggleFilters,
    };
};

function mapStateToProps(state, ownProps) {
    let permissionKey = ownProps.entity?.permission;
    let permissions = state.config.permission.crud_permission[permissionKey];

    return {
        create: permissions ? permissions.create : false,
        enabledVideoTutorial:
            state.config?.license === 'free_trial' && state.config?.userData?.userFlow,
    };
}

const ENTITIES_WITH_VIDEO = [
    ACTIVITIES.trueName,
    AGENDA.trueName,
    CONTACTS.trueName,
    COMPANIES.trueName,
    OPPORTUNITIES.trueName,
];

const EntityMenu = memo(
    ({
        className,
        toggleFilters,
        entity,
        create,
        withFilters,
        canCreate,
        withBreadcrumb,
        withSectionTitle,
        withOptions,
        withBulkOperations,
        withViews,
        quickFilters,
        useQuickView,
        bulkOperationsLiterals,
        bulkSelectedEntities,
        onFinishBulk,
        canMerge,
        canMassiveUpdate,
        canMassiveDelete,
        pipeline,
        useOnAdd = true,
        options,
        sectionTitle,
        onAdd,
        addButtonOptions,
        folderSelectedArr,
        onClickBreadcrumb,
        searchComponent,
        isDisabled,
        customBulkDelete,
        getCanClearAll,
        clearSpecificFilter,
        addButtonLabel,
        help,
        actions = [],
        enabledVideoTutorial,
    }) => {
        const { crossFiltersTour, totalActiveFilters: numberFilters } = useCrossFilters({ entity });

        const handleToggle = useCallback(() => {
            toggleFilters(entity);
            intercomHideAndShow();
            userflowHideAndShow('hide');
        }, [entity, toggleFilters]);

        const renderActions = useMemo(() => {
            if (!actions?.length) return null;
            return actions.map((current, index) => {
                return (
                    <div key={index} className="fm-entity-action">
                        {current}
                    </div>
                );
            });
        }, [actions]);

        const renderFilters = useMemo(() => {
            let haveFilters = numberFilters > 0;

            return (
                <div className="fm-entity-action filter-icon-container">
                    {!haveFilters && (
                        <Button
                            type="terciary"
                            size="medium"
                            icon="filterList"
                            onClick={handleToggle}
                        >
                            {getLiteral('action_filters')}
                        </Button>
                    )}
                    {haveFilters && (
                        <Button
                            type="secondary"
                            size="medium"
                            icon="filterListActive"
                            onClick={handleToggle}
                        >
                            {getLiteralWithParameters('label_filters_button', [numberFilters])}
                        </Button>
                    )}
                </div>
            );
        }, [numberFilters, handleToggle]);

        const renderButton = useMemo(() => {
            const innerAdd = () => {
                let addLink = `${entity?.route}/new`;
                if (window.location.href.includes('/map')) addLink = `${entity?.route}/map/new`;
                ensureRoute(addLink);
            };

            return (
                <div className="fm-entity-action add-button-container">
                    {onAdd && (
                        <Button size="medium" onClick={onAdd} isDisabled={isDisabled}>
                            {addButtonLabel || getLiteral('action_create')}
                        </Button>
                    )}
                    {!onAdd && (
                        <Button size="medium" isDisabled={isDisabled} onClick={innerAdd}>
                            {addButtonLabel || getLiteral('action_create')}
                        </Button>
                    )}
                </div>
            );
        }, [onAdd, isDisabled, addButtonLabel, entity?.route]);

        const renderOptionsButton = useMemo(() => {
            const button = (
                <Button
                    className="fm-entity-action-add-button-option"
                    size="medium"
                    icon="arrowDropDown"
                    iconPosition="right"
                    isDisabled={isDisabled}
                >
                    {getLiteral('action_add')}
                </Button>
            );

            if (isDisabled) return button;

            return <MenuHoi items={addButtonOptions}>{button}</MenuHoi>;
        }, [addButtonOptions, isDisabled]);

        const renderAdd = useMemo(() => {
            return addButtonOptions && addButtonOptions.length > 0
                ? renderOptionsButton
                : renderButton;
        }, [addButtonOptions, renderButton, renderOptionsButton]);

        const renderBreadcrumb = useMemo(() => {
            if (!folderSelectedArr?.length > 0) return [];
            const finalSelectedArr = folderSelectedArr.map((current) => {
                current.text = current.name;
                return current;
            });
            return (
                <div className="fm-entity-breadcrumb">
                    <Breadcrumbs items={finalSelectedArr} onClick={onClickBreadcrumb} />
                </div>
            );
        }, [folderSelectedArr, onClickBreadcrumb]);

        const renderSectionTitle = useMemo(() => {
            return <div className="fm-entity-section-title">{getLiteral(sectionTitle)}</div>;
        }, [sectionTitle]);

        const renderOptions = useMemo(() => {
            if (!options) return;
            return <div className="fm-entity-action fm-entity-options">{options}</div>;
        }, [options]);

        const onClickHelper = useCallback(() => {
            if (entity?.trueName && ENTITIES_WITH_VIDEO.includes(entity.trueName))
                logEvent({
                    event: entity.trueName,
                    submodule: 'emptyScreen ',
                    functionality: 'video',
                });
        }, [entity?.trueName]);

        const renderHelper = useMemo(() => {
            if (!entity) return null;
            if (enabledVideoTutorial && ENTITIES_WITH_VIDEO.includes(entity.trueName)) {
                let buttonLiteral = '';
                let classes = ['fm-entity-action', 'help-menu-container'];
                switch (entity.trueName) {
                    case ACTIVITIES.trueName:
                        buttonLiteral = getLiteral('helptext_activities_one_minute');
                        classes.push('help-menu-container--activities');
                        break;
                    case AGENDA.trueName:
                        buttonLiteral = getLiteral('helptext_calendar_one_minute');
                        classes.push('help-menu-container--calendar');
                        break;
                    case CONTACTS.trueName:
                        buttonLiteral = getLiteral('helptext_contacts_one_minute');
                        classes.push('help-menu-container--contacts');
                        break;
                    case COMPANIES.trueName:
                        buttonLiteral = getLiteral('helptext_accounts_one_minute');
                        classes.push('help-menu-container--accounts');
                        break;
                    case OPPORTUNITIES.trueName:
                        buttonLiteral = getLiteral('helptext_opportunities_one_minute');
                        classes.push('help-menu-container--opportunities');
                        break;
                }

                return (
                    <div className={classes.join(' ')}>
                        <Button
                            type="promotion"
                            icon="execute"
                            iconPosition="right"
                            onClick={onClickHelper}
                        >
                            {buttonLiteral}
                        </Button>
                    </div>
                );
            }
            return (
                <MenuHelp
                    className="fm-entity-action help-menu-container"
                    help={help}
                    entity={entity}
                />
            );
        }, [entity, help, enabledVideoTutorial, onClickHelper]);

        let withAdd = (create || canCreate) && useOnAdd;

        let literals = {};
        const classes = ['fm-entity-menu'];
        if (className) classes.push(className);
        if (bulkOperationsLiterals) literals = { ...literals, ...bulkOperationsLiterals };

        // TODO
        // When making the filters in menu:
        // Implement logic for views interaction from menu.
        // The logic will should be shared with NewEntityFilters in order to prevent repeating it.
        // At the moment it only prints the empty select field.
        // Also check wich labels are necessary and which not.

        const labelsViews = useMemo(
            () => ({
                views: getLiteral('label_views'),
                viewsPlaceholder: getLiteral('label_selectone'),
                viewsNoOptionsPlaceholder: getLiteral('label_no_options'),
                viewsLoadingPlaceholder: getLiteral('wait_loading'),
                modalTitle: getLiteral('label_new_view'),
                modalConfirmText: getLiteral('action_save'),
                modalCancelText: getLiteral('action_cancel'),
                modalViewName: getLiteral('label_view_name'),
                modalViewNamePlaceholder: getLiteral('placeholder_text_field'),
                modalSaveAs: getLiteral('label_save_as'),
                modalSaveAsPlaceholder: getLiteral('label_selectone'),
            }),
            [],
        );

        return (
            <div className={classes.join(' ')}>
                <div className="fm-entity-menu-left">
                    {searchComponent && searchComponent}
                    {!!quickFilters && (
                        <MenuFilters
                            entity={entity}
                            useQuickFilters={!!quickFilters}
                            useQuickView={useQuickView}
                            defaultFilters={quickFilters}
                            getCanClearAll={getCanClearAll}
                            clearSpecificFilter={clearSpecificFilter}
                            defaultQuickFilters={quickFilters || []}
                        />
                    )}
                    {withBreadcrumb && renderBreadcrumb}
                    {withSectionTitle && renderSectionTitle}
                    {withViews && <FilterViews labels={labelsViews} />}
                    {pipeline}
                    {withBulkOperations && (
                        <EntityMenuBulkActions
                            entity={entity}
                            bulkSelectedEntities={bulkSelectedEntities}
                            onFinishBulk={onFinishBulk}
                            literals={literals}
                            canMerge={canMerge}
                            canMassiveUpdate={canMassiveUpdate}
                            canMassiveDelete={canMassiveDelete}
                            customBulkDelete={customBulkDelete}
                        />
                    )}
                </div>
                <div className="fm-entity-menu-right">
                    {renderActions}
                    <EnableSync entity={entity} />
                    {renderHelper}
                    {withFilters && renderFilters}
                    {withOptions && renderOptions}
                    {withAdd && renderAdd}
                    {crossFiltersTour}
                </div>
            </div>
        );
    },
);

EntityMenu.propTypes = {
    ...propTypes,
    entity: PropTypes.object,
    useOnAdd: PropTypes.bool,
    actions: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityMenu);
