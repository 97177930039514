import React, { createContext, useState, useMemo, useCallback } from 'react';
import { putDashboards } from 'services/Dashboards';

const DashboardContext = createContext();

const DashboardContextProvider = ({ children }) => {
    const [dashboards, setDashboards] = useState([]);
    const [activeDashboard, setActiveDashboard] = useState();

    const updateConfig = useCallback(
        (config) => {
            let newActive = {
                ...activeDashboard,
                config,
            };
            let newDashboards = JSON.parse(JSON.stringify(dashboards));
            let idx = dashboards.findIndex((c) => c.id === newActive.id);
            newDashboards[idx] = newActive;

            // updating state
            setActiveDashboard({
                ...activeDashboard,
                config,
            });
            setDashboards(newDashboards);

            // Updating backend or rollback
            putDashboards(newDashboards).catch((e) => {
                console.error(e);
                setActiveDashboard(activeDashboard);
                setDashboards(dashboards);
            });
        },
        [activeDashboard, dashboards],
    );

    const providerValue = useMemo(() => {
        return {
            dashboards,
            setDashboards,
            activeDashboard,
            setActiveDashboard,
            updateConfig,
        };
    }, [activeDashboard, dashboards, updateConfig]);

    return <DashboardContext.Provider value={providerValue}>{children}</DashboardContext.Provider>;
};

export { DashboardContext, DashboardContextProvider };
