import Context from 'managers/Context';
import CrudModel from './CrudModel';
import {
    formatLargeNumber,
    formatLargeAmountCurrency,
    formatNumberWithSeparator,
} from 'utils/amount';
import { getStringAsCurrency } from 'utils/currency';
import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import { KPIS } from 'constants/Entities';
import {
    mapConditions,
    getConditionsString,
    transformDataConditions,
} from '@web/web5/dist/components/Conditions';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { toCrudV2SelectValue } from 'utils/crud';

const frequency = {
    day: 'label_daily',
    week: 'label_weekly',
    month: 'label_monthly',
    quarter: 'label_quarterly',
    fourMonthly: 'label_four_monthly',
    semester: 'label_semesterly',
    year: 'label_annually',
};

export default class GoalKpiModel extends CrudModel {
    static standardToCrud(data, mappedSchema) {
        const {
            id,
            owner,
            ownerName,
            ownerSurname,
            teamVisible,
            kpi,
            name,
            frequency,
            valuesDefined,
            order,
            assignment,
            teamGoals,
            condition,
            startDate,
            definedStartDate,
            isReadOnly,
            conditionSimple,
            kpiEditable,
        } = data;

        const manager = Context.entityManager.getEntitiesManager(KPIS);

        let ownerLabel = ownerName;
        if (ownerSurname) ownerLabel = `${ownerName} ${ownerSurname}`;

        let goal;
        let goalToggle = false;
        let valuesDefinedValues;

        if (assignment === 'teamaggregated' && teamGoals) {
            const users = manager.getUsersTree(owner);
            // maybe they want this solution of repeat values tomorrow
            if (valuesDefined && valuesDefined.length === 1) {
                goal = valuesDefined[0].toString();
                valuesDefinedValues = teamGoals.reduce((obj, userGoal) => {
                    obj[userGoal.owner] = userGoal.valuesDefined[0].toString();
                    return obj;
                }, {});
                users.forEach((user) => {
                    if (!valuesDefinedValues[user.id]) valuesDefinedValues[user.id] = '0';
                });
            } else {
                goalToggle = true;
                goal = valuesDefined.reduce((acc, value) => acc + value, 0);
                valuesDefinedValues = teamGoals.reduce((obj, userGoal) => {
                    obj[userGoal.owner] = manager.transformPeriodsFromServer(
                        userGoal.valuesDefined,
                        frequency,
                    );
                    return obj;
                }, {});
                const periodLength = teamGoals[0].valuesDefined.length;
                const valuesDefinedForUsersWithoutGoal = Array(periodLength).fill(0);
                users.forEach((user) => {
                    if (!valuesDefinedValues[user.id]) {
                        valuesDefinedValues[user.id] = manager.transformPeriodsFromServer(
                            valuesDefinedForUsersWithoutGoal,
                            frequency,
                        );
                    }
                });
            }
        } else {
            if (valuesDefined && valuesDefined.length === 0) {
                // excepcional case, OLD goal without @goal, but @goals (or vice versa)
                goal = data.currentTargetValue || 0;
            } else if (valuesDefined && valuesDefined.length === 1) {
                goal = valuesDefined[0] || '';
            } else {
                goalToggle = true;
                valuesDefinedValues = manager.transformPeriodsFromServer(valuesDefined, frequency);
            }
        }

        let begins;
        if (frequency && definedStartDate) {
            begins = manager.calculateWhenBegins(frequency, definedStartDate);
        }

        let type;
        let finalCondition;
        if (condition && !isEmptyObject(condition)) {
            finalCondition = mapConditions(condition);

            const { locale, dateFormat, kpiList } = data.extraData;
            const selectedKpi = kpiList.find((kpi) => kpi.id === data.kpi);
            if (locale && dateFormat && selectedKpi) {
                finalCondition = transformDataConditions(
                    { ...finalCondition },
                    {
                        fieldPrefix: selectedKpi?.entity,
                        dateFormat,
                        locale,
                    },
                    false,
                );
            }
        } else if (conditionSimple) {
            type = conditionSimple.id;
        }

        return {
            id,
            owner: { value: owner.toString(), label: ownerLabel },
            isTeamVisible: teamVisible,
            kpiId: kpi,
            goalName: name,
            period: frequency,
            begins,
            valuesDefined: valuesDefinedValues,
            goal,
            goalToggle,
            order,
            assignment,
            teamGoals,
            isReadOnly,
            type,
            condition: finalCondition,
            conditionSimple,
            kpiEditable,
        };
    }

    static toCrudV1(data, mappedSchema) {
        let baseData = this.standardToCrud(data, mappedSchema);
        return baseData;
    }

    static toCrudV2(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);

        const v2Data = toCrudV2SelectValue(baseData, [
            'owner',
            'assignment',
            'kpiId',
            'type',
            'period',
        ]);

        return { ...baseData, ...v2Data };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toDetail(data) {
        const manager = Context.entityManager.getEntitiesManager(KPIS);

        let performancePercentage;
        if (!data.valuePerformed) performancePercentage = 0;
        else
            performancePercentage = Math.min(
                parseInt((data.valuePerformed / data.currentTargetValue) * 100, 10) || 0,
                999,
            );

        let valuePerformedFormatted = formatNumberWithSeparator(data.valuePerformed);
        let currentTargetValueFormatted = data.currentTargetValue
            ? formatNumberWithSeparator(data.currentTargetValue)
            : '0';

        if (data.useCurrency) {
            const currencySymbol = Context?.config?.userData?.currencySymbol;
            valuePerformedFormatted = getStringAsCurrency(valuePerformedFormatted, currencySymbol);
            currentTargetValueFormatted = getStringAsCurrency(
                currentTargetValueFormatted,
                currencySymbol,
            );
        }

        let periodicityLiteral;
        if (data.frequency && data.definedStartDate) {
            periodicityLiteral = manager.getPeriodFooter(data.frequency, data.definedStartDate);
        }

        let conditionsString;
        let finalCondition;
        if (data.condition && !isEmptyObject(data.condition)) {
            finalCondition = mapConditions(data.condition);
            const { locale, dateFormat, kpiList } = data.extraData;
            const selectedKpi = kpiList.find((kpi) => kpi.id === data.kpi);
            if (locale && dateFormat && selectedKpi) {
                finalCondition = transformDataConditions(
                    { ...finalCondition },
                    {
                        fieldPrefix: selectedKpi?.entity,
                        dateFormat,
                        locale,
                    },
                    false,
                );
            }
            conditionsString = getConditionsString(finalCondition, locale);
        }

        return {
            ...data,
            performancePercentage,
            valuePerformedFormatted,
            currentTargetValueFormatted,
            periodicityLiteral,
            conditionsString,
            mappedCondition: finalCondition,
        };
    }

    static toList({ data, beforeListData, parentId }) {
        const manager = Context.entityManager.getEntitiesManager(KPIS);
        const newData = data.map((current, index) => {
            let ownerName = current.ownerName;
            if (current.ownerSurname) ownerName = `${ownerName} ${current.ownerSurname}`;
            const ownerImg = getSrcUserCircleAvatar(current.owner);
            let assignmentLabel = '';
            if (current.assignment === 'user') {
                if (current.idParent === -1) {
                    assignmentLabel = getLiteral('label_individual');
                } else {
                    assignmentLabel = getLiteral('label_individual_aggregate');
                }
            } else if (current.assignment === 'team') {
                assignmentLabel = current.teamVisible
                    ? getLiteral('label_team')
                    : getLiteral('label_team_no_visible');
            } else if (current.assignment === 'teamaggregated') {
                assignmentLabel = current.teamVisible
                    ? getLiteral('label_team_aggregate')
                    : getLiteral('label_team_aggregate_no_visible');
            }

            const loggedUser = Context?.config?.userData?.idUsuario;
            let creatorName = current.creatorName;
            if (current.creatorSurname) creatorName = `${creatorName} ${current.creatorSurname}`;
            const creatorLabel =
                loggedUser.toString() === current.creator.toString()
                    ? getLiteral('label_kpi_creator_myself')
                    : creatorName;

            const progressPercentage = Math.min(
                parseInt((current.valuePerformed / current.currentTargetValue) * 100, 10) || 0,
                999,
            );

            let progressValueText = formatLargeAmountCurrency(
                current.valuePerformed,
                current.useCurrency,
            );
            let goalShorten = formatLargeAmountCurrency(
                current.currentTargetValue,
                current.useCurrency,
            );

            let periodicityLiteral;
            if (current.frequency && current.definedStartDate) {
                periodicityLiteral = manager.getPeriodFooter(
                    current.frequency,
                    current.definedStartDate,
                );
            }

            let frequencyLiteral = getLiteral(frequency[current.frequency]);
            if (periodicityLiteral) {
                frequencyLiteral = `${frequencyLiteral} (${periodicityLiteral})`;
            }

            let type = '';
            if (current.condition && !isEmptyObject(current.condition))
                type = getLiteral('label_custom_type');
            else if (current.conditionSimple && !isEmptyObject(current.conditionSimple)) {
                const typeField = current?.conditionSimple?.field?.toLowerCase() || '';
                const typeId = current?.conditionSimple?.id || '';
                type = beforeListData?.[typeField]?.[typeId]?.label || '';
            }

            const category = getLiteral(current.category);
            let name = current.name;
            if (!name) {
                name = category.length > 20 ? category.substring(0, 20) : category;
            }

            let obj = {
                rawData: { ...current },
                id: current.id,
                name,
                category,
                type,
                assignmentLabel,
                goal: goalShorten,
                frequency: frequencyLiteral,
                creator: current.creator,
                creatorLabel,
                creatorName,
                ownerSrc: ownerImg.src,
                ownerPlaceholder: ownerImg.fallbackSrc,
                ownerName,
                progressValue: current.valuePerformed,
                progressPercentage,
                progressValueText,
                order: current.order,
                isGroup: current.assignment === 'teamaggregated',
            };

            if (parentId) {
                obj.isChildren = true;
                obj.parentId = parentId;
            }

            return obj;
        });

        return newData;
    }

    static getSimpleForBackend(entity) {
        const send = {
            id: entity.id,
            kpiId: entity.kpiId,
            parameters: JSON.stringify({
                '@goal': entity.parameterGoal ? parseInt(entity.parameterGoal) : 0,
                '@period': entity.parameterPeriod,
                '@scope': {
                    '@scopevalue': entity.idUser,
                    '@scopetype': entity.parameterScope,
                },
            }),
            order: entity.order,
            userId: entity.idUser,
            isTeamVisible: entity.isTeamVisible,
            goalName: entity.goalName,
        };
        return send;
    }

    constructor(entity, kpi, repository, fromBackend = false) {
        // TODO GOALS 2.0 --> this method will be used when all refactored?
        super(entity);
        if (fromBackend) {
            this.entity.parameters = JSON.parse(this.entity.parameters);
            this.entity.drilldownparameters =
                (this.entity.drilldownparameters && JSON.parse(this.entity.drilldownparameters)) ||
                {};
            const periods = repository['@period'].values;
            for (const period of periods) {
                if (period.value === this.entity.parameters['@period']) {
                    this.entity.parameters['@period_name'] = period.name;
                    break;
                }
            }
            this.entity.kpi = kpi;
            this.entity.repository = repository;
        }
    }

    // TODO GOALS 2.0 --> check if we are using all this staff
    get kpiName() {
        return this.entity.kpiname;
    }

    get kpiId() {
        return this.entity.kpiid;
    }

    get kpiIsReadOnly() {
        return this.entity.kpi.readOnly === 1;
    }

    get goalName() {
        return this.entity.goalname || getLiteral(this.entity.kpiname);
    }

    get parameterGoal() {
        return this.entity.parameters['@goal'] ? this.entity.parameters['@goal'].toString() : '0';
    }

    get parameterGoalShorten() {
        let parameterValue = this.entity.parameters['@goal']
            ? this.entity.parameters['@goal'].toString()
            : '0';
        parameterValue = formatLargeNumber(parameterValue);
        if (this.entity.kpi.useCurrency === 1) {
            const currencySymbol = Context?.config?.userData?.currencySymbol;
            return getStringAsCurrency(parameterValue, currencySymbol);
        }
        return parameterValue;
    }

    get parameterPeriod() {
        return this.entity.parameters['@period'];
    }

    get parameterPeriodName() {
        return this.entity.parameters['@period_name'];
    }

    get parameterScope() {
        return this.entity.parameters['@scope']['@scopetype'];
    }

    get parameterScopeValue() {
        return this.entity.parameters['@scope']['@scopevalue'];
    }

    get parameterScopeType() {
        const scopeType = this.entity.parameters['@scope']['@scopetype'];

        const scopeValue = this.entity.parameters['@scope']['@scopevalue'];

        const userId = this.entity.userid;

        if (scopeType === 'user') {
            if (scopeValue !== userId) {
                return 'boss';
            }
            return 'personal';
        }
        return 'team';

        return this.entity.parameters['@scope']['@scopetype'];
    }

    get parameterScopeTypeLiteral() {
        const scopeType = this.entity.parameters['@scope']['@scopetype'];
        const scopeValue = this.entity.parameters['@scope']['@scopevalue'];
        const userId = this.entity.userid;
        const values = this.entity.repository['@scope'].values;
        for (const value of values) {
            if (value.value === scopeType) {
                if (scopeType === 'user' && scopeValue.toString() !== userId.toString()) return '';
                return value.name;
            }
        }
        return '';
    }

    get parameterScopeTypeIndividualLiteral() {
        const scopeType = this.entity.parameters['@scope']['@scopetype'];
        const scopeValue = this.entity.parameters['@scope']['@scopevalue'];
        const userId = this.entity.userid;
        const values = this.entity.repository['@scope'].values;
        for (const value of values) {
            if (value.value === scopeType) {
                if (scopeType === 'user' && scopeValue.toString() !== userId.toString()) return '';
                if (scopeType === 'user') return value.name;
            }
        }
        return '';
    }

    get calculatedValue() {
        return this.entity.calculatedvalue;
    }

    get calculatedValueWithFormat() {
        let calculatedValue = this.entity.calculatedvalue;
        calculatedValue = formatLargeNumber(calculatedValue);
        if (this.entity.kpi.useCurrency === 1) {
            const currencySymbol = Context?.config?.userData?.currencySymbol;
            return getStringAsCurrency(calculatedValue, currencySymbol);
        }
        return calculatedValue;
    }

    get percentageValue() {
        if (!this.entity.parameters['@goal']) return 0;
        if (this.entity.parameters['@goal'] === 0) return this.entity.parameters['@goal'];
        return Math.max(
            parseInt(
                ((this.entity.calculatedvalue / this.entity.parameters['@goal']) * 100).toFixed(),
                10,
            ),
            0,
        );
    }

    get isReadOnly() {
        return this.entity.readonly === 1;
    }

    get idUser() {
        return this.entity.userid;
    }

    get order() {
        return this.entity.order;
    }

    set order(order) {
        this.entity.order = order;
    }

    get useCurrency() {
        return this.entity.kpi.useCurrency === 1;
    }

    get goalType() {
        return this.entity.kpi.goalType;
    }

    get id() {
        return this.entity.id;
    }

    get userCreadoId() {
        return this.entity.usercreado;
    }

    get userCreadoIdString() {
        return this.entity.usercreado.toString();
    }

    get userCreadoName() {
        return this.entity.usernamecreado;
    }

    get hasDrilldown() {
        return this.entity.hasdrilldown === 1;
    }

    get isTeamVisible() {
        return this.entity.isteamvisible;
    }

    get kpiDescription() {
        return this.entity.kpidescription;
    }

    get category() {
        return this.entity.category;
    }
}
