import React, { memo, useMemo } from 'react';
import { ATTENDEE_STATUS } from 'constants/Constants';
import { Icon, Link, useTheme, Avatar } from 'hoi-poi-ui';
import { ATTENDEE_TYPES } from 'constants/Constants';
import { CONTACTS } from 'constants/Entities';
import { getRoute } from 'utils/routes';
import { getUserSfmUrl } from 'utils/getUrl';
import { getSrcUserCircleAvatar, getSrcContactCircleAvatar } from 'utils/getSrcAvatar';

import './styles.scss';

const AttendeesRow = memo(({ attendee }) => {
    const theme = useTheme();

    const avatar = useMemo(() => {
        switch (attendee.AttendeeType) {
            case ATTENDEE_TYPES.contact:
                return (
                    <Avatar icon="singleUser" src={getSrcContactCircleAvatar(attendee.id).src} />
                );
            case ATTENDEE_TYPES.userAttendee:
                return <Avatar icon="singleUser" src={getSrcUserCircleAvatar(attendee.id).src} />;
            case ATTENDEE_TYPES.externalAttendee:
                return <Avatar icon="singleUser" />;
        }
    }, [attendee]);

    const statusIcon = useMemo(() => {
        let icon;
        switch (attendee.Status) {
            case ATTENDEE_STATUS.accepted:
                icon = <Icon name="taskChecked" color={theme.colors.green500} size="small" />;
                break;
            case ATTENDEE_STATUS.rejected:
                icon = <Icon name="cancel" color={theme.colors.red500} size="small" />;
                break;
            case ATTENDEE_STATUS.tentative:
                icon = <Icon name="help" color={theme.colors.yellow400} size="small" />;
                break;
            case ATTENDEE_STATUS.noResponse:
            default:
                icon = <Icon name="help" color={theme.colors.neutral700} size="small" />;
                break;
        }

        return icon;
    }, [attendee, theme]);

    const attendeeURL = useMemo(() => {
        switch (attendee.AttendeeType) {
            case ATTENDEE_TYPES.contact:
                return getRoute(CONTACTS.route + `/${attendee.id}`);
            case ATTENDEE_TYPES.userAttendee:
                return getUserSfmUrl(attendee.id);
        }
    }, [attendee?.AttendeeType, attendee?.id]);

    return (
        <div className="fm-agenda-small-detail__attendee-row">
            <div className="fm-agenda-small-detail__attendee-row__avatar">
                {avatar}
                {statusIcon}
            </div>
            <div className="fm-agenda-small-detail__attendee-row__texts">
                {attendee.Name && <Link href={attendeeURL}>{attendee.Name}</Link>}
                {attendee.Address && (
                    <Link
                        type="caption"
                        className="fm-agenda-small-detail__attendee-row__email"
                        href={`mailto:${attendee.Address}`}
                    >
                        <Icon name="email" />
                        {attendee.Address}
                    </Link>
                )}
            </div>
        </div>
    );
});

export default AttendeesRow;
