import React, { memo, useRef, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { Icon, Text, Spacer, Slider, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import debounce from 'utils/debounce';

import { DashboardContext } from '../../DashboardContext';

import SidePanel from 'components/SidePanel';
import SidePanelSection from 'components/SidePanel/SidePanelSection';

import './styles.scss';

const DashboardCustomizePanel = memo(({ onRef, onClose }) => {
    const timer = useRef();
    const { activeDashboard, updateConfig } = useContext(DashboardContext);
    const theme = useTheme();
    const [color, setColor] = useState(
        activeDashboard?.config?.background?.color || theme.colors.neutralBase,
    );
    const [brightness, setBrightness] = useState(
        activeDashboard?.config?.background?.brightness || 30,
    );

    const onChangeBrightness = useCallback((value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setBrightness(value);
        }, 250);
    }, []);

    const ColorBoxes = useMemo(() => {
        const BACKGROUND_COLORS = [
            theme.colors.orange200,
            theme.colors.blue200,
            theme.colors.aqua200,
            theme.colors.green200,
            theme.colors.yellow200,
            theme.colors.red200,
            theme.colors.purple200,
            theme.colors.neutral400,
            theme.colors.neutral800,
            theme.colors.neutralBase,
        ];

        return BACKGROUND_COLORS.map((bcolor) => (
            <div
                className={classnames('fm-dashboard-customize-panel__color', {
                    'fm-dashboard-customize-panel__color--active': bcolor === color,
                })}
                onClick={() => {
                    setColor(bcolor);
                }}
                style={{ backgroundColor: bcolor }}
            >
                {bcolor === color ? (
                    <Icon
                        name="check"
                        size="big"
                        color={
                            color === theme.colors.neutral700
                                ? theme.colors.neutral400
                                : theme.colors.neutral700
                        }
                    />
                ) : undefined}
            </div>
        ));
    }, [
        color,
        theme.colors.aqua200,
        theme.colors.blue200,
        theme.colors.green200,
        theme.colors.neutral400,
        theme.colors.neutral700,
        theme.colors.neutral800,
        theme.colors.neutralBase,
        theme.colors.orange200,
        theme.colors.purple200,
        theme.colors.red200,
        theme.colors.yellow200,
    ]);

    useEffect(() => {
        if (
            activeDashboard?.config?.background?.color === color &&
            activeDashboard?.config?.background?.brightness === brightness
        )
            return;

        updateConfig({
            ...activeDashboard?.config,
            background: {
                color,
                brightness,
            },
        });
    }, [activeDashboard?.config, brightness, color, updateConfig]);

    return (
        <SidePanel onRef={onRef}>
            <div className="fm-dashboard-customize-panel">
                <div className="fm-dashboard-customize-panel__header">
                    <Icon name="close" onClick={onClose} size="large" />
                    <div className="fm-dashboard-customize-panel__titles">
                        <Text type="h6">{getLiteral('title_customise_dashboard')}</Text>
                        <Spacer y={1} />
                        <Text color="neutral700">
                            {getLiteral('label_customise_dashboard_desc')}
                        </Text>
                    </div>
                </div>
                <SidePanelSection text={getLiteral('label_background')} />
                <Spacer y={6} />
                <Text>{getLiteral('label_colour')} </Text>
                <Spacer y={4} />
                <div className="fm-dashboard-customize-panel__colors-box">{ColorBoxes}</div>
                <Spacer y={6} />
                <Text>{getLiteral('label_brightness')} </Text>
                <Spacer y={4} />
                <Slider
                    className="fm-dashboard-customize-panel__brightness"
                    value={brightness}
                    onChange={onChangeBrightness}
                    min={20}
                    max={100}
                    isFullWidth
                />
            </div>
        </SidePanel>
    );
});

export default DashboardCustomizePanel;
